import React from 'react';
import { Link } from 'react-router-dom';

const ContentBox = ({ appAbsPath, createGa4Event, Box, Blueright, Flowers, Stars, Hot, Fire, Affordable, Sky1, Sky2, showPara, showTopContent, showForwardIcon, allowLazyLoad, handleClose, origin }) => {
    
    return (
        <>
            <div className='contentbox'>
                {showTopContent && (
                    <div className='box-wrapper'>
                        <div className='content'>
                            <div>
                                <h2 className='sec-title fw-bold text-md-start text-center'>What Are You Looking For?</h2>
                                <p className='sec-desc text-md-start text-center'>BeyondWalls is an AI-powered property search platform that offers a comprehensive property consultancy service designed to simplify homebuying for you! This is your one-stop homebuying portal that brings you the right comparison of properties, for the right locations, to help you make the right decision.</p>
                            </div>
                        </div>
                    </div>
                )}
                <div className='box-wrapper'>
                    <Link to={`${appAbsPath}/projects?is_ready_to_move=true`} onClick={() => { 
                        createGa4Event('home_page_looking_for', 'home_page_looking_for', 'Ready to Move In');
                        handleClose();
                        }} className='text-decoration-none'>
                        <div className='box box1'>
                            <div>
                                <h2 className='boxhead'>Ready to Move In</h2>
                                {showPara && (
                                    <p className='sec-desc text-md-start'>Find your dream home that's prepared for immediate occupancy, offering seamless transition and comfort.</p>
                                )}
                            </div>
                            <div className='imgbox'>
                                <div>
                                    <img
                                        {...(allowLazyLoad ? { 'data-src': Box } : { src: Box })}
                                        alt="area-map"
                                        className={`img-fluid boximg ${allowLazyLoad ? 'lazy' : ''}`}
                                    />
                                </div>
                                {showForwardIcon && (
                                    <div>
                                        <img src={Blueright} alt="area-map" className='img-fluid' />
                                    </div>
                                )}
                            </div>
                        </div>
                    </Link>
                </div>
                <div className='box-wrapper'>
                    <Link to={`${appAbsPath}/projects?is_great_view=true`} onClick={() => { 
                        createGa4Event('home_page_looking_for', 'home_page_looking_for', 'What a View');
                        handleClose();
                        }} className='text-decoration-none'>
                        <div className='box box2'>
                            <div>
                                <h2 className='boxhead'>What a View</h2>
                                {showPara && (
                                    <p className='sec-desc text-md-start'>Enjoy stunning vistas that redefine everyday living, providing a serene backdrop to your daily life.</p>
                                )}
                            </div>
                            <div className='imgbox'>
                                <div>
                                    <img
                                        {...(allowLazyLoad ? { 'data-src': Flowers } : { src: Flowers })}
                                        alt="area-map"
                                        className={`img-fluid flowers ${allowLazyLoad ? 'lazy' : ''}`}
                                    />
                                </div>
                                {showForwardIcon && (
                                    <div>
                                        <img src={Blueright} alt="area-map" className='img-fluid' />
                                    </div>
                                )}
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
            <div className='contentbox contentbox1'>
                <Link to={`${appAbsPath}/projects?is_new_in_town=true`} onClick={() => { 
                    createGa4Event('home_page_looking_for', 'home_page_looking_for', 'New In Town');
                    handleClose();
                    }} className='text-decoration-none'>
                    <div className='box box3'>
                        <div>
                            <h2 className='boxhead'>New In Town</h2>
                            {showPara && (
                                <p className='sec-desc text-md-start'>Explore the latest developments offering modern living solutions, designed to meet the dynamic needs of urban dwellers.</p>
                            )}
                        </div>
                        <div className='imgbox'>
                            <div>
                                <img
                                    {...(allowLazyLoad ? { 'data-src': Stars } : { src: Stars })}
                                    alt="area-map"
                                    className={`img-fluid stars ${allowLazyLoad ? 'lazy' : ''}`}
                                />
                            </div>
                            {showForwardIcon && (
                                <div>
                                    <img src={Blueright} alt="area-map" className='img-fluid' />
                                </div>
                            )}
                        </div>
                    </div>
                </Link>
                <Link to={`${appAbsPath}/projects?is_hot_selling=true`} onClick={() => { 
                    createGa4Event('home_page_looking_for', 'home_page_looking_for', 'Hot Selling');
                    handleClose();
                    }} className='text-decoration-none'>
                    <div className='box box4'>
                        <div>
                            <h2 className='boxhead'>Hot <br />Selling</h2>
                            {showPara && (
                                <p className='sec-desc text-md-start'>Grab the hottest properties with high demand and value, ensuring a smart investment choice for discerning buyers.</p>
                            )}
                        </div>
                        <div className='hotselldiv'>
                            <img src={Hot} alt="area-map" className='img-fluid hotsell' />
                            <p className='hotselltxt visible-ipad'>HOT</p>
                        </div>
                        <div className='imgbox'>
                            <div>
                                <img
                                    {...(allowLazyLoad ? { 'data-src': Fire } : { src: Fire })}
                                    alt="area-map"
                                    className={`img-fluid ${allowLazyLoad ? 'lazy' : ''}`}
                                />
                            </div>
                            {showForwardIcon && (
                                <div>
                                    <img src={Blueright} alt="area-map" className='img-fluid' />
                                </div>
                            )}
                        </div>
                    </div>
                </Link>
                <Link to={`${appAbsPath}/projects?is_affordable=true`} onClick={() => { 
                    createGa4Event('home_page_looking_for', 'home_page_looking_for', 'Affordable Homes');
                    handleClose();
                    }} className='text-decoration-none'>
                    <div className='box box5'>
                        <div>
                            <h2 className='boxhead'>Affordable Homes</h2>
                            {showPara && (
                                <p className='sec-desc text-md-start'>Experience quality living at prices that fit your budget, without compromising on comfort or style.</p>
                            )}
                        </div>
                        <div className='imgbox'>
                            <div>
                                <img
                                    {...(allowLazyLoad ? { 'data-src': Affordable } : { src: Affordable })}
                                    alt="area-map"
                                    className={`img-fluid afford ${allowLazyLoad ? 'lazy' : ''}`}
                                />
                            </div>
                            {showForwardIcon && (
                                <div>
                                    <img src={Blueright} alt="area-map" className='img-fluid' />
                                </div>
                            )}
                        </div>
                    </div>
                </Link>
                <Link to={`${appAbsPath}/projects?is_live_in_sky=true`} onClick={() => { 
                    createGa4Event('home_page_looking_for', 'home_page_looking_for', 'Live In The Sky');
                    handleClose();
                    }} className='text-decoration-none'>
                    <div className='box box6'>
                        <div>
                            <h2 className='boxhead'>Live in the sky</h2>
                            {showPara && (
                                <p className='sec-desc text-md-start'>Elevate your living experience with high-rise luxury and breathtaking views, where every sunrise and sunset becomes a spectacle.</p>
                            )}
                        </div>

                        <div className='imgbox'>
                            <div>
                                <img
                                    {...(allowLazyLoad ? { 'data-src': Sky1 } : { src: Sky1 })}
                                    alt="area-map"
                                    className={`img-fluid sky1 ${allowLazyLoad ? 'lazy' : ''}`}
                                />
                                <img
                                    {...(allowLazyLoad ? { 'data-src': Sky2 } : { src: Sky2 })}
                                    alt="area-map"
                                    className={`img-fluid sky2 ${allowLazyLoad ? 'lazy' : ''}`}
                                />
                            </div>
                            {showForwardIcon && (
                                <div>
                                    <img src={Blueright} alt="area-map" className='img-fluid' />
                                </div>
                            )}
                        </div>
                    </div>
                </Link>
            </div>
        </>
    );
};

export default ContentBox;
