// Define coordinates for the polygon (Kothrud area)
const polygonCoordsKothrud = [
  [
    18.50639,
    73.82673
  ],
  [
    18.50757,
    73.82621
  ],
  [
    18.50882,
    73.82556
  ],
  [
    18.51083,
    73.82442
  ],
  [
    18.51173,
    73.82391
  ],
  [
    18.51205,
    73.82388
  ],
  [
    18.51343,
    73.82377
  ],
  [
    18.51519,
    73.82407
  ],
  [
    18.51595,
    73.82448
  ],
  [
    18.5164,
    73.82256
  ],
  [
    18.51716,
    73.8223
  ],
  [
    18.51798,
    73.82203
  ],
  [
    18.51835,
    73.82194
  ],
  [
    18.51879,
    73.82188
  ],
  [
    18.51907,
    73.82183
  ],
  [
    18.5194,
    73.82192
  ],
  [
    18.51982,
    73.82222
  ],
  [
    18.5201,
    73.82245
  ],
  [
    18.52029,
    73.82262
  ],
  [
    18.52038,
    73.82251
  ],
  [
    18.52059,
    73.82195
  ],
  [
    18.52087,
    73.82098
  ],
  [
    18.52111,
    73.82025
  ],
  [
    18.52133,
    73.81976
  ],
  [
    18.5216,
    73.81936
  ],
  [
    18.52249,
    73.81814
  ],
  [
    18.52308,
    73.81714
  ],
  [
    18.52314,
    73.81705
  ],
  [
    18.52335,
    73.8167
  ],
  [
    18.52335,
    73.81659
  ],
  [
    18.52345,
    73.81657
  ],
  [
    18.52352,
    73.81652
  ],
  [
    18.52366,
    73.81642
  ],
  [
    18.52376,
    73.81637
  ],
  [
    18.52419,
    73.81619
  ],
  [
    18.52434,
    73.81612
  ],
  [
    18.52441,
    73.81608
  ],
  [
    18.52444,
    73.81605
  ],
  [
    18.52447,
    73.81601
  ],
  [
    18.52451,
    73.81598
  ],
  [
    18.52456,
    73.81595
  ],
  [
    18.52461,
    73.81592
  ],
  [
    18.52464,
    73.81591
  ],
  [
    18.52468,
    73.8159
  ],
  [
    18.52472,
    73.81587
  ],
  [
    18.52476,
    73.81584
  ],
  [
    18.52478,
    73.81583
  ],
  [
    18.52481,
    73.81581
  ],
  [
    18.52484,
    73.81578
  ],
  [
    18.52486,
    73.81577
  ],
  [
    18.52489,
    73.81576
  ],
  [
    18.52492,
    73.81576
  ],
  [
    18.52496,
    73.81574
  ],
  [
    18.52504,
    73.81569
  ],
  [
    18.52521,
    73.81557
  ],
  [
    18.52533,
    73.8155
  ],
  [
    18.52542,
    73.81544
  ],
  [
    18.52514,
    73.81498
  ],
  [
    18.5243,
    73.81354
  ],
  [
    18.52421,
    73.81307
  ],
  [
    18.52392,
    73.81148
  ],
  [
    18.52388,
    73.80934
  ],
  [
    18.5235,
    73.80924
  ],
  [
    18.5229,
    73.80908
  ],
  [
    18.52222,
    73.8084
  ],
  [
    18.52143,
    73.80669
  ],
  [
    18.52137,
    73.8058
  ],
  [
    18.52132,
    73.80504
  ],
  [
    18.52148,
    73.80314
  ],
  [
    18.52088,
    73.80073
  ],
  [
    18.52001,
    73.79997
  ],
  [
    18.51765,
    73.79902
  ],
  [
    18.51507,
    73.79861
  ],
  [
    18.51233,
    73.79858
  ],
  [
    18.51189,
    73.79862
  ],
  [
    18.51124,
    73.79869
  ],
  [
    18.51143,
    73.79822
  ],
  [
    18.51167,
    73.79592
  ],
  [
    18.51251,
    73.79422
  ],
  [
    18.51315,
    73.79252
  ],
  [
    18.51382,
    73.7911
  ],
  [
    18.51446,
    73.78929
  ],
  [
    18.51446,
    73.78752
  ],
  [
    18.51446,
    73.78739
  ],
  [
    18.51398,
    73.78693
  ],
  [
    18.51318,
    73.78644
  ],
  [
    18.51174,
    73.78561
  ],
  [
    18.5109,
    73.78515
  ],
  [
    18.509,
    73.78397
  ],
  [
    18.50808,
    73.78359
  ],
  [
    18.50753,
    73.78348
  ],
  [
    18.50728,
    73.78365
  ],
  [
    18.50727,
    73.78387
  ],
  [
    18.50723,
    73.78482
  ],
  [
    18.50724,
    73.78511
  ],
  [
    18.50721,
    73.78516
  ],
  [
    18.50718,
    73.7852
  ],
  [
    18.50713,
    73.78524
  ],
  [
    18.50708,
    73.78526
  ],
  [
    18.50703,
    73.78527
  ],
  [
    18.507,
    73.78527
  ],
  [
    18.50697,
    73.78526
  ],
  [
    18.50693,
    73.78518
  ],
  [
    18.50677,
    73.78486
  ],
  [
    18.50674,
    73.78477
  ],
  [
    18.50673,
    73.7847
  ],
  [
    18.50674,
    73.78463
  ],
  [
    18.50676,
    73.78455
  ],
  [
    18.5068,
    73.78444
  ],
  [
    18.50684,
    73.78432
  ],
  [
    18.5069,
    73.78415
  ],
  [
    18.50695,
    73.78398
  ],
  [
    18.5071,
    73.78377
  ],
  [
    18.50701,
    73.78336
  ],
  [
    18.50697,
    73.78318
  ],
  [
    18.50693,
    73.78303
  ],
  [
    18.50691,
    73.78296
  ],
  [
    18.5068,
    73.78276
  ],
  [
    18.5067,
    73.78255
  ],
  [
    18.50664,
    73.78245
  ],
  [
    18.506,
    73.78302
  ],
  [
    18.50535,
    73.78467
  ],
  [
    18.50533,
    73.78532
  ],
  [
    18.50528,
    73.78541
  ],
  [
    18.50523,
    73.78552
  ],
  [
    18.50518,
    73.78561
  ],
  [
    18.50516,
    73.78571
  ],
  [
    18.50516,
    73.78581
  ],
  [
    18.50515,
    73.7859
  ],
  [
    18.5051,
    73.78611
  ],
  [
    18.50506,
    73.78624
  ],
  [
    18.50493,
    73.78681
  ],
  [
    18.50487,
    73.78711
  ],
  [
    18.50481,
    73.78732
  ],
  [
    18.50476,
    73.78744
  ],
  [
    18.50468,
    73.78758
  ],
  [
    18.50458,
    73.78768
  ],
  [
    18.5045,
    73.78775
  ],
  [
    18.50443,
    73.78781
  ],
  [
    18.50434,
    73.78786
  ],
  [
    18.50415,
    73.78792
  ],
  [
    18.50359,
    73.78797
  ],
  [
    18.50301,
    73.78796
  ],
  [
    18.50282,
    73.78789
  ],
  [
    18.50281,
    73.78789
  ],
  [
    18.50272,
    73.78783
  ],
  [
    18.50262,
    73.78774
  ],
  [
    18.50254,
    73.78767
  ],
  [
    18.50247,
    73.78759
  ],
  [
    18.50243,
    73.78752
  ],
  [
    18.50237,
    73.78742
  ],
  [
    18.50227,
    73.78721
  ],
  [
    18.50213,
    73.78688
  ],
  [
    18.50195,
    73.78641
  ],
  [
    18.5017,
    73.78579
  ],
  [
    18.50162,
    73.78563
  ],
  [
    18.50151,
    73.78549
  ],
  [
    18.50129,
    73.7853
  ],
  [
    18.50099,
    73.7852
  ],
  [
    18.50053,
    73.78513
  ],
  [
    18.49981,
    73.78501
  ],
  [
    18.49932,
    73.78492
  ],
  [
    18.49877,
    73.78482
  ],
  [
    18.49871,
    73.78481
  ],
  [
    18.49847,
    73.78477
  ],
  [
    18.49813,
    73.78467
  ],
  [
    18.49786,
    73.78453
  ],
  [
    18.49758,
    73.78511
  ],
  [
    18.49682,
    73.78673
  ],
  [
    18.49625,
    73.78785
  ],
  [
    18.49626,
    73.78791
  ],
  [
    18.49643,
    73.78795
  ],
  [
    18.49698,
    73.7882
  ],
  [
    18.49744,
    73.7885
  ],
  [
    18.4979,
    73.78884
  ],
  [
    18.49822,
    73.78916
  ],
  [
    18.49793,
    73.78923
  ],
  [
    18.49738,
    73.78984
  ],
  [
    18.4968,
    73.79028
  ],
  [
    18.49441,
    73.79413
  ],
  [
    18.49297,
    73.79645
  ],
  [
    18.4926,
    73.79697
  ],
  [
    18.4925,
    73.79721
  ],
  [
    18.49237,
    73.79777
  ],
  [
    18.49233,
    73.79798
  ],
  [
    18.49229,
    73.79805
  ],
  [
    18.49227,
    73.79815
  ],
  [
    18.49227,
    73.79825
  ],
  [
    18.49228,
    73.79836
  ],
  [
    18.4923,
    73.79846
  ],
  [
    18.49234,
    73.79861
  ],
  [
    18.49234,
    73.79871
  ],
  [
    18.49234,
    73.79876
  ],
  [
    18.49234,
    73.7988
  ],
  [
    18.49231,
    73.79897
  ],
  [
    18.4923,
    73.79918
  ],
  [
    18.49224,
    73.79955
  ],
  [
    18.49223,
    73.79965
  ],
  [
    18.49221,
    73.79986
  ],
  [
    18.49216,
    73.79997
  ],
  [
    18.49211,
    73.80002
  ],
  [
    18.49204,
    73.80004
  ],
  [
    18.49193,
    73.80004
  ],
  [
    18.49171,
    73.8
  ],
  [
    18.49158,
    73.79997
  ],
  [
    18.49151,
    73.79998
  ],
  [
    18.49149,
    73.80004
  ],
  [
    18.4915,
    73.80011
  ],
  [
    18.49158,
    73.80022
  ],
  [
    18.49165,
    73.80042
  ],
  [
    18.49166,
    73.80051
  ],
  [
    18.49166,
    73.80064
  ],
  [
    18.49165,
    73.80075
  ],
  [
    18.49161,
    73.8009
  ],
  [
    18.49155,
    73.80104
  ],
  [
    18.4915,
    73.8011
  ],
  [
    18.49146,
    73.80116
  ],
  [
    18.4914,
    73.80121
  ],
  [
    18.49135,
    73.80122
  ],
  [
    18.4913,
    73.80122
  ],
  [
    18.49125,
    73.8012
  ],
  [
    18.49117,
    73.80117
  ],
  [
    18.49108,
    73.80114
  ],
  [
    18.49078,
    73.8011
  ],
  [
    18.49062,
    73.80113
  ],
  [
    18.49051,
    73.80122
  ],
  [
    18.49042,
    73.8013
  ],
  [
    18.49037,
    73.80147
  ],
  [
    18.49032,
    73.80157
  ],
  [
    18.49027,
    73.80173
  ],
  [
    18.49016,
    73.8019
  ],
  [
    18.48996,
    73.80212
  ],
  [
    18.48989,
    73.80219
  ],
  [
    18.4898,
    73.80229
  ],
  [
    18.48973,
    73.80246
  ],
  [
    18.48968,
    73.80268
  ],
  [
    18.48958,
    73.803
  ],
  [
    18.48951,
    73.8032
  ],
  [
    18.48943,
    73.80344
  ],
  [
    18.48938,
    73.80364
  ],
  [
    18.48938,
    73.8038
  ],
  [
    18.48938,
    73.80388
  ],
  [
    18.48937,
    73.804
  ],
  [
    18.48934,
    73.80411
  ],
  [
    18.48927,
    73.80433
  ],
  [
    18.48926,
    73.8045
  ],
  [
    18.48927,
    73.80458
  ],
  [
    18.48931,
    73.80472
  ],
  [
    18.48932,
    73.80481
  ],
  [
    18.48932,
    73.80495
  ],
  [
    18.48932,
    73.80507
  ],
  [
    18.48935,
    73.80518
  ],
  [
    18.48939,
    73.80528
  ],
  [
    18.4895,
    73.80542
  ],
  [
    18.48952,
    73.80548
  ],
  [
    18.48953,
    73.80556
  ],
  [
    18.48952,
    73.80561
  ],
  [
    18.48948,
    73.80567
  ],
  [
    18.48943,
    73.80574
  ],
  [
    18.48937,
    73.80583
  ],
  [
    18.48931,
    73.80593
  ],
  [
    18.48931,
    73.80606
  ],
  [
    18.48931,
    73.80624
  ],
  [
    18.48932,
    73.80642
  ],
  [
    18.48933,
    73.80652
  ],
  [
    18.48933,
    73.8066
  ],
  [
    18.48934,
    73.80667
  ],
  [
    18.48934,
    73.80675
  ],
  [
    18.48933,
    73.8068
  ],
  [
    18.4893,
    73.80685
  ],
  [
    18.48928,
    73.80688
  ],
  [
    18.4895,
    73.80691
  ],
  [
    18.49,
    73.80707
  ],
  [
    18.49077,
    73.80744
  ],
  [
    18.4915,
    73.80786
  ],
  [
    18.49214,
    73.80837
  ],
  [
    18.49217,
    73.80839
  ],
  [
    18.49214,
    73.80945
  ],
  [
    18.49238,
    73.81289
  ],
  [
    18.49238,
    73.81293
  ],
  [
    18.49238,
    73.81301
  ],
  [
    18.49235,
    73.81308
  ],
  [
    18.49237,
    73.81331
  ],
  [
    18.4924,
    73.81335
  ],
  [
    18.49246,
    73.81344
  ],
  [
    18.49289,
    73.81464
  ],
  [
    18.493,
    73.81496
  ],
  [
    18.49306,
    73.81513
  ],
  [
    18.49314,
    73.81519
  ],
  [
    18.49318,
    73.81532
  ],
  [
    18.49326,
    73.81559
  ],
  [
    18.49331,
    73.81576
  ],
  [
    18.49345,
    73.81631
  ],
  [
    18.49346,
    73.81636
  ],
  [
    18.49346,
    73.81638
  ],
  [
    18.49361,
    73.817
  ],
  [
    18.4938,
    73.81761
  ],
  [
    18.49383,
    73.81768
  ],
  [
    18.49384,
    73.8177
  ],
  [
    18.49388,
    73.81776
  ],
  [
    18.49394,
    73.8178
  ],
  [
    18.49408,
    73.81784
  ],
  [
    18.49419,
    73.8179
  ],
  [
    18.49425,
    73.81795
  ],
  [
    18.49426,
    73.81797
  ],
  [
    18.49431,
    73.81802
  ],
  [
    18.49436,
    73.81809
  ],
  [
    18.49441,
    73.81816
  ],
  [
    18.49446,
    73.81822
  ],
  [
    18.49451,
    73.81829
  ],
  [
    18.49458,
    73.81836
  ],
  [
    18.49464,
    73.8184
  ],
  [
    18.4947,
    73.81844
  ],
  [
    18.4948,
    73.81848
  ],
  [
    18.49564,
    73.81887
  ],
  [
    18.49601,
    73.81908
  ],
  [
    18.49603,
    73.81911
  ],
  [
    18.49618,
    73.81927
  ],
  [
    18.49665,
    73.81992
  ],
  [
    18.4971,
    73.82048
  ],
  [
    18.49728,
    73.82078
  ],
  [
    18.49752,
    73.82165
  ],
  [
    18.49754,
    73.82201
  ],
  [
    18.49745,
    73.82234
  ],
  [
    18.49749,
    73.82251
  ],
  [
    18.49753,
    73.82265
  ],
  [
    18.49756,
    73.82276
  ],
  [
    18.49758,
    73.82285
  ],
  [
    18.4976,
    73.82296
  ],
  [
    18.49764,
    73.82312
  ],
  [
    18.49767,
    73.82325
  ],
  [
    18.49771,
    73.82334
  ],
  [
    18.49774,
    73.82341
  ],
  [
    18.49778,
    73.82345
  ],
  [
    18.49783,
    73.82347
  ],
  [
    18.4979,
    73.8235
  ],
  [
    18.49795,
    73.8237
  ],
  [
    18.49795,
    73.824
  ],
  [
    18.49792,
    73.82421
  ],
  [
    18.49787,
    73.82452
  ],
  [
    18.49787,
    73.82455
  ],
  [
    18.49784,
    73.82489
  ],
  [
    18.49784,
    73.82494
  ],
  [
    18.49786,
    73.82508
  ],
  [
    18.4979,
    73.82515
  ],
  [
    18.49794,
    73.82523
  ],
  [
    18.49806,
    73.82554
  ],
  [
    18.49809,
    73.82563
  ],
  [
    18.49811,
    73.82575
  ],
  [
    18.49812,
    73.82585
  ],
  [
    18.49813,
    73.82602
  ],
  [
    18.49813,
    73.82605
  ],
  [
    18.49822,
    73.82601
  ],
  [
    18.49888,
    73.82575
  ],
  [
    18.4993,
    73.82543
  ],
  [
    18.49962,
    73.82527
  ],
  [
    18.50023,
    73.82506
  ],
  [
    18.50072,
    73.82496
  ],
  [
    18.50073,
    73.82496
  ],
  [
    18.50124,
    73.82484
  ],
  [
    18.50164,
    73.82465
  ],
  [
    18.50191,
    73.82438
  ],
  [
    18.50224,
    73.82415
  ],
  [
    18.50298,
    73.82377
  ],
  [
    18.50382,
    73.82328
  ],
  [
    18.50434,
    73.82295
  ],
  [
    18.50485,
    73.82247
  ],
  [
    18.50492,
    73.82264
  ],
  [
    18.50499,
    73.8228
  ],
  [
    18.50503,
    73.82289
  ],
  [
    18.50513,
    73.82315
  ],
  [
    18.50524,
    73.82345
  ],
  [
    18.50533,
    73.82377
  ],
  [
    18.5054,
    73.82401
  ],
  [
    18.50546,
    73.82425
  ],
  [
    18.50552,
    73.82442
  ],
  [
    18.50561,
    73.82476
  ],
  [
    18.50569,
    73.82509
  ],
  [
    18.50573,
    73.82519
  ],
  [
    18.50578,
    73.82525
  ],
  [
    18.50583,
    73.82528
  ],
  [
    18.50588,
    73.82529
  ],
  [
    18.50589,
    73.82553
  ],
  [
    18.50593,
    73.82569
  ],
  [
    18.50607,
    73.82609
  ],
  [
    18.50629,
    73.8266
  ],
  [
    18.50636,
    73.82676
  ]
];

export default polygonCoordsKothrud;