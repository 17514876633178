// Define coordinates for the polygon (Baner area)
const polygonCoordsBaner = [
  [
    18.55112,
    73.80459
  ],
  [
    18.55325,
    73.80299
  ],
  [
    18.55497,
    73.80054
  ],
  [
    18.55605,
    73.79937
  ],
  [
    18.55621,
    73.79931
  ],
  [
    18.55636,
    73.79921
  ],
  [
    18.55669,
    73.79895
  ],
  [
    18.55701,
    73.79873
  ],
  [
    18.55756,
    73.79839
  ],
  [
    18.55783,
    73.79827
  ],
  [
    18.55865,
    73.79781
  ],
  [
    18.55867,
    73.7978
  ],
  [
    18.55895,
    73.79765
  ],
  [
    18.55907,
    73.79763
  ],
  [
    18.5593,
    73.79752
  ],
  [
    18.55952,
    73.79742
  ],
  [
    18.55968,
    73.79732
  ],
  [
    18.55983,
    73.79723
  ],
  [
    18.55979,
    73.797
  ],
  [
    18.55975,
    73.7966
  ],
  [
    18.5597,
    73.79632
  ],
  [
    18.55998,
    73.79576
  ],
  [
    18.56019,
    73.79549
  ],
  [
    18.56045,
    73.79525
  ],
  [
    18.56089,
    73.79489
  ],
  [
    18.56125,
    73.79467
  ],
  [
    18.56163,
    73.79449
  ],
  [
    18.562,
    73.7943
  ],
  [
    18.5631,
    73.79352
  ],
  [
    18.56325,
    73.79317
  ],
  [
    18.5641,
    73.79223
  ],
  [
    18.564,
    73.79145
  ],
  [
    18.56428,
    73.79016
  ],
  [
    18.5644,
    73.78976
  ],
  [
    18.56467,
    73.78887
  ],
  [
    18.56498,
    73.78877
  ],
  [
    18.56529,
    73.78861
  ],
  [
    18.56573,
    73.78845
  ],
  [
    18.56651,
    73.78838
  ],
  [
    18.56683,
    73.78841
  ],
  [
    18.56707,
    73.78856
  ],
  [
    18.56724,
    73.78876
  ],
  [
    18.56748,
    73.78904
  ],
  [
    18.56751,
    73.78907
  ],
  [
    18.56779,
    73.78936
  ],
  [
    18.56862,
    73.78993
  ],
  [
    18.56924,
    73.79014
  ],
  [
    18.56951,
    73.79023
  ],
  [
    18.57016,
    73.79032
  ],
  [
    18.57058,
    73.7903
  ],
  [
    18.57125,
    73.79018
  ],
  [
    18.57171,
    73.79
  ],
  [
    18.57243,
    73.78943
  ],
  [
    18.57276,
    73.78908
  ],
  [
    18.57339,
    73.78818
  ],
  [
    18.5736,
    73.78788
  ],
  [
    18.57561,
    73.78484
  ],
  [
    18.57528,
    73.78408
  ],
  [
    18.57464,
    73.78303
  ],
  [
    18.57389,
    73.7811
  ],
  [
    18.57365,
    73.78087
  ],
  [
    18.57359,
    73.78064
  ],
  [
    18.57365,
    73.77919
  ],
  [
    18.57371,
    73.77852
  ],
  [
    18.57374,
    73.77725
  ],
  [
    18.57372,
    73.77605
  ],
  [
    18.57369,
    73.77476
  ],
  [
    18.57368,
    73.77406
  ],
  [
    18.57369,
    73.77309
  ],
  [
    18.57371,
    73.77294
  ],
  [
    18.57379,
    73.77276
  ],
  [
    18.57419,
    73.77205
  ],
  [
    18.57443,
    73.77158
  ],
  [
    18.5748,
    73.77076
  ],
  [
    18.57501,
    73.77
  ],
  [
    18.57518,
    73.76936
  ],
  [
    18.57521,
    73.76916
  ],
  [
    18.57522,
    73.769
  ],
  [
    18.57517,
    73.76819
  ],
  [
    18.57505,
    73.76631
  ],
  [
    18.57496,
    73.76535
  ],
  [
    18.57491,
    73.76403
  ],
  [
    18.5749,
    73.76356
  ],
  [
    18.57184,
    73.76475
  ],
  [
    18.5692,
    73.76571
  ],
  [
    18.56918,
    73.76572
  ],
  [
    18.56963,
    73.76381
  ],
  [
    18.56125,
    73.76468
  ],
  [
    18.56105,
    73.76468
  ],
  [
    18.56052,
    73.76465
  ],
  [
    18.56017,
    73.76463
  ],
  [
    18.55998,
    73.76463
  ],
  [
    18.55951,
    73.76456
  ],
  [
    18.55867,
    73.7645
  ],
  [
    18.55856,
    73.7645
  ],
  [
    18.55824,
    73.7645
  ],
  [
    18.55771,
    73.76455
  ],
  [
    18.55712,
    73.76466
  ],
  [
    18.55625,
    73.76442
  ],
  [
    18.55605,
    73.76437
  ],
  [
    18.55577,
    73.76426
  ],
  [
    18.55563,
    73.76407
  ],
  [
    18.5555,
    73.764
  ],
  [
    18.55508,
    73.76393
  ],
  [
    18.55461,
    73.76379
  ],
  [
    18.55448,
    73.76307
  ],
  [
    18.55429,
    73.76296
  ],
  [
    18.55397,
    73.76294
  ],
  [
    18.55373,
    73.76305
  ],
  [
    18.55367,
    73.76325
  ],
  [
    18.54902,
    73.7658
  ],
  [
    18.54448,
    73.76936
  ],
  [
    18.54435,
    73.76951
  ],
  [
    18.54504,
    73.77096
  ],
  [
    18.54601,
    73.77286
  ],
  [
    18.54672,
    73.77378
  ],
  [
    18.54674,
    73.77384
  ],
  [
    18.54675,
    73.774
  ],
  [
    18.54671,
    73.77414
  ],
  [
    18.54662,
    73.77429
  ],
  [
    18.54662,
    73.7743
  ],
  [
    18.5467,
    73.77452
  ],
  [
    18.54666,
    73.77498
  ],
  [
    18.54675,
    73.77544
  ],
  [
    18.54694,
    73.77602
  ],
  [
    18.54713,
    73.77662
  ],
  [
    18.54762,
    73.77755
  ],
  [
    18.54765,
    73.77793
  ],
  [
    18.54756,
    73.7785
  ],
  [
    18.54738,
    73.77889
  ],
  [
    18.54714,
    73.77909
  ],
  [
    18.54677,
    73.77943
  ],
  [
    18.5463,
    73.77989
  ],
  [
    18.54608,
    73.78019
  ],
  [
    18.54603,
    73.78043
  ],
  [
    18.54603,
    73.78084
  ],
  [
    18.54606,
    73.7814
  ],
  [
    18.54607,
    73.78181
  ],
  [
    18.54625,
    73.78215
  ],
  [
    18.54678,
    73.78273
  ],
  [
    18.5469,
    73.78293
  ],
  [
    18.54695,
    73.78392
  ],
  [
    18.54695,
    73.78427
  ],
  [
    18.54687,
    73.7845
  ],
  [
    18.54649,
    73.78481
  ],
  [
    18.546,
    73.78519
  ],
  [
    18.54551,
    73.78577
  ],
  [
    18.54534,
    73.78649
  ],
  [
    18.54532,
    73.78686
  ],
  [
    18.54552,
    73.78739
  ],
  [
    18.54578,
    73.78769
  ],
  [
    18.54707,
    73.78829
  ],
  [
    18.54952,
    73.78836
  ],
  [
    18.5512,
    73.78885
  ],
  [
    18.55201,
    73.7898
  ],
  [
    18.5522,
    73.79032
  ],
  [
    18.55208,
    73.79068
  ],
  [
    18.55205,
    73.79176
  ],
  [
    18.55208,
    73.79197
  ],
  [
    18.55208,
    73.79233
  ],
  [
    18.552,
    73.79338
  ],
  [
    18.55192,
    73.79412
  ],
  [
    18.5519,
    73.79428
  ],
  [
    18.55188,
    73.7945
  ],
  [
    18.55187,
    73.79479
  ],
  [
    18.55184,
    73.79529
  ],
  [
    18.55178,
    73.79649
  ],
  [
    18.55176,
    73.79716
  ],
  [
    18.55167,
    73.79785
  ],
  [
    18.55161,
    73.79803
  ],
  [
    18.55157,
    73.79807
  ],
  [
    18.55115,
    73.80454
  ]
];

export default polygonCoordsBaner;