// Define coordinates for the polygon (Wakad area)
const polygonCoords = [
  [
    18.61181,
    73.76143
  ],
  [
    18.61167,
    73.76125
  ],
  [
    18.6115,
    73.76103
  ],
  [
    18.61131,
    73.76079
  ],
  [
    18.6109,
    73.76021
  ],
  [
    18.61044,
    73.75958
  ],
  [
    18.61015,
    73.75918
  ],
  [
    18.6092,
    73.75789
  ],
  [
    18.6088,
    73.75692
  ],
  [
    18.60819,
    73.75391
  ],
  [
    18.60648,
    73.75392
  ],
  [
    18.60618,
    73.75315
  ],
  [
    18.6059,
    73.75269
  ],
  [
    18.60561,
    73.75235
  ],
  [
    18.60547,
    73.7522
  ],
  [
    18.60653,
    73.75184
  ],
  [
    18.60675,
    73.75176
  ],
  [
    18.60659,
    73.75062
  ],
  [
    18.60617,
    73.74796
  ],
  [
    18.60535,
    73.74303
  ],
  [
    18.60488,
    73.7383
  ],
  [
    18.60395,
    73.73939
  ],
  [
    18.60145,
    73.74231
  ],
  [
    18.60041,
    73.74519
  ],
  [
    18.60027,
    73.74538
  ],
  [
    18.5999,
    73.74587
  ],
  [
    18.59961,
    73.74604
  ],
  [
    18.599,
    73.7464
  ],
  [
    18.59679,
    73.74728
  ],
  [
    18.59671,
    73.74734
  ],
  [
    18.59391,
    73.74939
  ],
  [
    18.59384,
    73.7503
  ],
  [
    18.59357,
    73.75099
  ],
  [
    18.59334,
    73.75181
  ],
  [
    18.59318,
    73.75237
  ],
  [
    18.59306,
    73.75264
  ],
  [
    18.59264,
    73.75312
  ],
  [
    18.59152,
    73.75333
  ],
  [
    18.59165,
    73.75363
  ],
  [
    18.59067,
    73.75404
  ],
  [
    18.58512,
    73.75674
  ],
  [
    18.58526,
    73.75683
  ],
  [
    18.5857,
    73.75726
  ],
  [
    18.58635,
    73.75792
  ],
  [
    18.58635,
    73.75792
  ],
  [
    18.58636,
    73.75794
  ],
  [
    18.58724,
    73.75889
  ],
  [
    18.58721,
    73.7589
  ],
  [
    18.58805,
    73.76023
  ],
  [
    18.58847,
    73.76142
  ],
  [
    18.58858,
    73.76222
  ],
  [
    18.58862,
    73.76321
  ],
  [
    18.58827,
    73.7651
  ],
  [
    18.58769,
    73.76768
  ],
  [
    18.58758,
    73.76816
  ],
  [
    18.58753,
    73.76867
  ],
  [
    18.58752,
    73.76876
  ],
  [
    18.58753,
    73.7693
  ],
  [
    18.5876,
    73.76998
  ],
  [
    18.58784,
    73.77114
  ],
  [
    18.5882,
    73.77222
  ],
  [
    18.58839,
    73.77304
  ],
  [
    18.58847,
    73.77399
  ],
  [
    18.58851,
    73.77528
  ],
  [
    18.58837,
    73.77638
  ],
  [
    18.58889,
    73.77634
  ],
  [
    18.58884,
    73.77717
  ],
  [
    18.58879,
    73.77768
  ],
  [
    18.58863,
    73.77839
  ],
  [
    18.58862,
    73.77853
  ],
  [
    18.58861,
    73.7787
  ],
  [
    18.58862,
    73.77893
  ],
  [
    18.58862,
    73.77964
  ],
  [
    18.58862,
    73.78002
  ],
  [
    18.58859,
    73.78074
  ],
  [
    18.58853,
    73.78165
  ],
  [
    18.58846,
    73.78257
  ],
  [
    18.58844,
    73.78278
  ],
  [
    18.58843,
    73.78296
  ],
  [
    18.5884,
    73.78324
  ],
  [
    18.58839,
    73.7833
  ],
  [
    18.58835,
    73.78362
  ],
  [
    18.58828,
    73.78423
  ],
  [
    18.58827,
    73.78432
  ],
  [
    18.58822,
    73.78462
  ],
  [
    18.58819,
    73.78482
  ],
  [
    18.58811,
    73.78516
  ],
  [
    18.58806,
    73.78538
  ],
  [
    18.58785,
    73.78642
  ],
  [
    18.58741,
    73.78861
  ],
  [
    18.58679,
    73.79079
  ],
  [
    18.58728,
    73.79014
  ],
  [
    18.58794,
    73.7892
  ],
  [
    18.58884,
    73.78826
  ],
  [
    18.59045,
    73.78675
  ],
  [
    18.59156,
    73.78576
  ],
  [
    18.59159,
    73.78574
  ],
  [
    18.59223,
    73.78518
  ],
  [
    18.59235,
    73.7851
  ],
  [
    18.59254,
    73.78498
  ],
  [
    18.59335,
    73.78447
  ],
  [
    18.59369,
    73.78427
  ],
  [
    18.59408,
    73.78403
  ],
  [
    18.59553,
    73.78315
  ],
  [
    18.59651,
    73.78254
  ],
  [
    18.59722,
    73.78212
  ],
  [
    18.59765,
    73.78183
  ],
  [
    18.5981,
    73.78153
  ],
  [
    18.59851,
    73.78125
  ],
  [
    18.59892,
    73.78096
  ],
  [
    18.59925,
    73.78074
  ],
  [
    18.59971,
    73.78044
  ],
  [
    18.59998,
    73.78026
  ],
  [
    18.6009,
    73.7796
  ],
  [
    18.60175,
    73.77905
  ],
  [
    18.60239,
    73.77859
  ],
  [
    18.60305,
    73.77788
  ],
  [
    18.60333,
    73.77755
  ],
  [
    18.60363,
    73.77721
  ],
  [
    18.60398,
    73.77674
  ],
  [
    18.60422,
    73.77641
  ],
  [
    18.60444,
    73.7761
  ],
  [
    18.60463,
    73.77586
  ],
  [
    18.60498,
    73.77542
  ],
  [
    18.6052,
    73.77515
  ],
  [
    18.60543,
    73.77486
  ],
  [
    18.60544,
    73.77484
  ],
  [
    18.60563,
    73.77461
  ],
  [
    18.60575,
    73.77447
  ],
  [
    18.60596,
    73.77422
  ],
  [
    18.60663,
    73.77342
  ],
  [
    18.60684,
    73.77314
  ],
  [
    18.60705,
    73.77286
  ],
  [
    18.60724,
    73.77263
  ],
  [
    18.6077,
    73.77207
  ],
  [
    18.60814,
    73.77153
  ],
  [
    18.60828,
    73.77136
  ],
  [
    18.6085,
    73.77108
  ],
  [
    18.60864,
    73.77094
  ],
  [
    18.60889,
    73.7707
  ],
  [
    18.60963,
    73.77008
  ],
  [
    18.61015,
    73.76965
  ],
  [
    18.61034,
    73.76949
  ],
  [
    18.61052,
    73.76934
  ],
  [
    18.61072,
    73.76917
  ],
  [
    18.611,
    73.76893
  ],
  [
    18.61117,
    73.76878
  ],
  [
    18.6118,
    73.76827
  ],
  [
    18.61405,
    73.76643
  ],
  [
    18.61424,
    73.76628
  ],
  [
    18.61457,
    73.76601
  ],
  [
    18.61539,
    73.76535
  ],
  [
    18.6161,
    73.76475
  ],
  [
    18.61692,
    73.76405
  ],
  [
    18.61741,
    73.76364
  ],
  [
    18.61501,
    73.76229
  ],
  [
    18.61291,
    73.76081
  ],
  [
    18.61207,
    73.76181
  ],
  [
    18.61181,
    73.76146
  ]
];

export default polygonCoords;