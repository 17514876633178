// Define coordinates for the polygon (Bavdhan area)
const polygonCoordsBavdhan = [
  [
    18.50754,
    73.78353
  ],
  [
    18.50809,
    73.78364
  ],
  [
    18.50901,
    73.78402
  ],
  [
    18.51091,
    73.7852
  ],
  [
    18.51175,
    73.78566
  ],
  [
    18.51376,
    73.78543
  ],
  [
    18.51544,
    73.785
  ],
  [
    18.51696,
    73.78397
  ],
  [
    18.51777,
    73.78355
  ],
  [
    18.51875,
    73.78304
  ],
  [
    18.51971,
    73.783
  ],
  [
    18.52016,
    73.78298
  ],
  [
    18.52222,
    73.78325
  ],
  [
    18.52358,
    73.78358
  ],
  [
    18.52434,
    73.78336
  ],
  [
    18.52573,
    73.78155
  ],
  [
    18.52574,
    73.78156
  ],
  [
    18.52579,
    73.78147
  ],
  [
    18.52606,
    73.78104
  ],
  [
    18.5261,
    73.78098
  ],
  [
    18.52632,
    73.78064
  ],
  [
    18.52745,
    73.77895
  ],
  [
    18.52754,
    73.77882
  ],
  [
    18.52825,
    73.77772
  ],
  [
    18.52824,
    73.77771
  ],
  [
    18.52789,
    73.7773
  ],
  [
    18.52758,
    73.77685
  ],
  [
    18.52729,
    73.77625
  ],
  [
    18.52669,
    73.77466
  ],
  [
    18.52653,
    73.77424
  ],
  [
    18.52637,
    73.77383
  ],
  [
    18.52616,
    73.7734
  ],
  [
    18.52596,
    73.77305
  ],
  [
    18.52576,
    73.77278
  ],
  [
    18.52604,
    73.77155
  ],
  [
    18.52672,
    73.76831
  ],
  [
    18.52694,
    73.76651
  ],
  [
    18.52727,
    73.76055
  ],
  [
    18.52649,
    73.75922
  ],
  [
    18.52387,
    73.75804
  ],
  [
    18.52346,
    73.75967
  ],
  [
    18.52228,
    73.7603
  ],
  [
    18.51995,
    73.76102
  ],
  [
    18.51637,
    73.76189
  ],
  [
    18.51441,
    73.76217
  ],
  [
    18.51422,
    73.76002
  ],
  [
    18.51008,
    73.7582
  ],
  [
    18.50883,
    73.75853
  ],
  [
    18.50685,
    73.76042
  ],
  [
    18.5056,
    73.76159
  ],
  [
    18.50423,
    73.76357
  ],
  [
    18.50316,
    73.76355
  ],
  [
    18.50199,
    73.76339
  ],
  [
    18.5008,
    73.76264
  ],
  [
    18.49979,
    73.76162
  ],
  [
    18.4973,
    73.75943
  ],
  [
    18.49589,
    73.7581
  ],
  [
    18.49497,
    73.75741
  ],
  [
    18.49366,
    73.75612
  ],
  [
    18.49275,
    73.75532
  ],
  [
    18.48102,
    73.77553
  ],
  [
    18.48514,
    73.77804
  ],
  [
    18.48538,
    73.77825
  ],
  [
    18.48569,
    73.77859
  ],
  [
    18.48595,
    73.77882
  ],
  [
    18.48606,
    73.7789
  ],
  [
    18.48613,
    73.77895
  ],
  [
    18.48634,
    73.77903
  ],
  [
    18.48666,
    73.77912
  ],
  [
    18.48674,
    73.77913
  ],
  [
    18.4871,
    73.77915
  ],
  [
    18.48737,
    73.77917
  ],
  [
    18.48767,
    73.77919
  ],
  [
    18.48828,
    73.77927
  ],
  [
    18.48899,
    73.77934
  ],
  [
    18.48994,
    73.77944
  ],
  [
    18.49019,
    73.77948
  ],
  [
    18.49044,
    73.77955
  ],
  [
    18.49066,
    73.77969
  ],
  [
    18.49077,
    73.7798
  ],
  [
    18.49102,
    73.78005
  ],
  [
    18.49105,
    73.78008
  ],
  [
    18.49143,
    73.7805
  ],
  [
    18.49145,
    73.78052
  ],
  [
    18.49181,
    73.78088
  ],
  [
    18.49199,
    73.78102
  ],
  [
    18.4921,
    73.78109
  ],
  [
    18.49225,
    73.7811
  ],
  [
    18.49241,
    73.78109
  ],
  [
    18.49268,
    73.78099
  ],
  [
    18.49294,
    73.78089
  ],
  [
    18.49353,
    73.78064
  ],
  [
    18.49414,
    73.7804
  ],
  [
    18.49434,
    73.78034
  ],
  [
    18.49449,
    73.78032
  ],
  [
    18.49468,
    73.78033
  ],
  [
    18.4948,
    73.78035
  ],
  [
    18.49498,
    73.78046
  ],
  [
    18.49524,
    73.78072
  ],
  [
    18.49552,
    73.78113
  ],
  [
    18.49556,
    73.7812
  ],
  [
    18.49596,
    73.78183
  ],
  [
    18.49662,
    73.78287
  ],
  [
    18.49721,
    73.78379
  ],
  [
    18.49757,
    73.78431
  ],
  [
    18.49768,
    73.78444
  ],
  [
    18.49784,
    73.78457
  ],
  [
    18.49811,
    73.78471
  ],
  [
    18.49845,
    73.78481
  ],
  [
    18.49869,
    73.78485
  ],
  [
    18.49875,
    73.78486
  ],
  [
    18.4993,
    73.78496
  ],
  [
    18.49979,
    73.78505
  ],
  [
    18.50051,
    73.78517
  ],
  [
    18.50097,
    73.78524
  ],
  [
    18.50127,
    73.78534
  ],
  [
    18.50149,
    73.78553
  ],
  [
    18.5016,
    73.78567
  ],
  [
    18.50168,
    73.78583
  ],
  [
    18.50193,
    73.78645
  ],
  [
    18.50211,
    73.78692
  ],
  [
    18.50225,
    73.78725
  ],
  [
    18.50235,
    73.78746
  ],
  [
    18.50241,
    73.78756
  ],
  [
    18.50245,
    73.78763
  ],
  [
    18.50252,
    73.78771
  ],
  [
    18.5026,
    73.78778
  ],
  [
    18.5027,
    73.78787
  ],
  [
    18.50279,
    73.78793
  ],
  [
    18.5028,
    73.78793
  ],
  [
    18.50299,
    73.788
  ],
  [
    18.50357,
    73.78801
  ],
  [
    18.50413,
    73.78796
  ],
  [
    18.50432,
    73.7879
  ],
  [
    18.50441,
    73.78785
  ],
  [
    18.50448,
    73.78779
  ],
  [
    18.50456,
    73.78772
  ],
  [
    18.50466,
    73.78762
  ],
  [
    18.50474,
    73.78748
  ],
  [
    18.50479,
    73.78736
  ],
  [
    18.50485,
    73.78715
  ],
  [
    18.50491,
    73.78685
  ],
  [
    18.50504,
    73.78628
  ],
  [
    18.50508,
    73.78615
  ],
  [
    18.50513,
    73.78594
  ],
  [
    18.50514,
    73.78585
  ],
  [
    18.50514,
    73.78575
  ],
  [
    18.50516,
    73.78565
  ],
  [
    18.50521,
    73.78556
  ],
  [
    18.50526,
    73.78545
  ],
  [
    18.50531,
    73.78536
  ],
  [
    18.50533,
    73.78471
  ],
  [
    18.50598,
    73.78306
  ],
  [
    18.50662,
    73.78249
  ],
  [
    18.50668,
    73.78259
  ],
  [
    18.50678,
    73.7828
  ],
  [
    18.50689,
    73.783
  ],
  [
    18.50691,
    73.78307
  ],
  [
    18.50695,
    73.78322
  ],
  [
    18.50699,
    73.7834
  ],
  [
    18.50708,
    73.78381
  ],
  [
    18.50693,
    73.78402
  ],
  [
    18.50688,
    73.78419
  ],
  [
    18.50682,
    73.78436
  ],
  [
    18.50678,
    73.78448
  ],
  [
    18.50674,
    73.78459
  ],
  [
    18.50672,
    73.78467
  ],
  [
    18.50671,
    73.78474
  ],
  [
    18.50672,
    73.78481
  ],
  [
    18.50675,
    73.7849
  ],
  [
    18.50691,
    73.78522
  ],
  [
    18.50695,
    73.7853
  ],
  [
    18.50698,
    73.78531
  ],
  [
    18.50701,
    73.78531
  ],
  [
    18.50706,
    73.7853
  ],
  [
    18.50711,
    73.78528
  ],
  [
    18.50716,
    73.78524
  ],
  [
    18.50719,
    73.7852
  ],
  [
    18.50722,
    73.78515
  ],
  [
    18.50721,
    73.78486
  ],
  [
    18.50725,
    73.78391
  ],
  [
    18.50726,
    73.78369
  ],
  [
    18.50751,
    73.78352
  ]
];

export default polygonCoordsBavdhan;