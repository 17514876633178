// Define coordinates for the polygon (Hadapsar area)
const polygonCoordsHadapsar = [
  [
    18.50013,
    73.91714
  ],
  [
    18.49903,
    73.91626
  ],
  [
    18.49878,
    73.91605
  ],
  [
    18.49854,
    73.91585
  ],
  [
    18.49828,
    73.91567
  ],
  [
    18.49818,
    73.9156
  ],
  [
    18.49509,
    73.91321
  ],
  [
    18.49476,
    73.91326
  ],
  [
    18.49417,
    73.91324
  ],
  [
    18.49303,
    73.91363
  ],
  [
    18.492,
    73.91363
  ],
  [
    18.49126,
    73.91385
  ],
  [
    18.49058,
    73.91424
  ],
  [
    18.4901,
    73.91514
  ],
  [
    18.48936,
    73.91606
  ],
  [
    18.48873,
    73.91611
  ],
  [
    18.48814,
    73.91569
  ],
  [
    18.48766,
    73.91462
  ],
  [
    18.4872,
    73.91418
  ],
  [
    18.487,
    73.91383
  ],
  [
    18.4867,
    73.91361
  ],
  [
    18.48629,
    73.91372
  ],
  [
    18.48598,
    73.91383
  ],
  [
    18.48565,
    73.91379
  ],
  [
    18.48525,
    73.91385
  ],
  [
    18.48495,
    73.91411
  ],
  [
    18.48482,
    73.91448
  ],
  [
    18.4844,
    73.91524
  ],
  [
    18.48428,
    73.91555
  ],
  [
    18.48418,
    73.9158
  ],
  [
    18.48399,
    73.91627
  ],
  [
    18.48384,
    73.91739
  ],
  [
    18.48379,
    73.918
  ],
  [
    18.48356,
    73.91811
  ],
  [
    18.4835,
    73.91818
  ],
  [
    18.48324,
    73.91876
  ],
  [
    18.48259,
    73.922
  ],
  [
    18.48238,
    73.92506
  ],
  [
    18.48209,
    73.92527
  ],
  [
    18.48157,
    73.92616
  ],
  [
    18.48041,
    73.92703
  ],
  [
    18.47976,
    73.92754
  ],
  [
    18.47954,
    73.92787
  ],
  [
    18.47914,
    73.92901
  ],
  [
    18.47912,
    73.92907
  ],
  [
    18.47912,
    73.93039
  ],
  [
    18.47912,
    73.93134
  ],
  [
    18.47852,
    73.93137
  ],
  [
    18.47781,
    73.93142
  ],
  [
    18.47777,
    73.93142
  ],
  [
    18.47746,
    73.93145
  ],
  [
    18.47736,
    73.93146
  ],
  [
    18.47694,
    73.93149
  ],
  [
    18.47688,
    73.93149
  ],
  [
    18.47612,
    73.93148
  ],
  [
    18.4761,
    73.93148
  ],
  [
    18.47598,
    73.93149
  ],
  [
    18.47586,
    73.9315
  ],
  [
    18.47502,
    73.93155
  ],
  [
    18.47493,
    73.93156
  ],
  [
    18.47425,
    73.93163
  ],
  [
    18.47383,
    73.93171
  ],
  [
    18.4738,
    73.93172
  ],
  [
    18.4733,
    73.93184
  ],
  [
    18.47323,
    73.93185
  ],
  [
    18.47292,
    73.93194
  ],
  [
    18.4722,
    73.93209
  ],
  [
    18.47184,
    73.93215
  ],
  [
    18.47184,
    73.9329
  ],
  [
    18.47168,
    73.93441
  ],
  [
    18.47172,
    73.93523
  ],
  [
    18.4696,
    73.93516
  ],
  [
    18.46816,
    73.93512
  ],
  [
    18.46815,
    73.93585
  ],
  [
    18.46804,
    73.93761
  ],
  [
    18.4684,
    73.9384
  ],
  [
    18.46866,
    73.93967
  ],
  [
    18.46901,
    73.94053
  ],
  [
    18.46916,
    73.94113
  ],
  [
    18.46799,
    73.94157
  ],
  [
    18.46779,
    73.94202
  ],
  [
    18.46714,
    73.94366
  ],
  [
    18.46985,
    73.94467
  ],
  [
    18.47032,
    73.94703
  ],
  [
    18.47021,
    73.9519
  ],
  [
    18.47027,
    73.95644
  ],
  [
    18.47029,
    73.95797
  ],
  [
    18.4703,
    73.95886
  ],
  [
    18.47023,
    73.95923
  ],
  [
    18.47016,
    73.9595
  ],
  [
    18.47134,
    73.95971
  ],
  [
    18.47215,
    73.95693
  ],
  [
    18.4732,
    73.95411
  ],
  [
    18.47442,
    73.95212
  ],
  [
    18.47608,
    73.94989
  ],
  [
    18.47665,
    73.94914
  ],
  [
    18.47746,
    73.94808
  ],
  [
    18.47813,
    73.94729
  ],
  [
    18.48013,
    73.9444
  ],
  [
    18.48027,
    73.94443
  ],
  [
    18.48043,
    73.94446
  ],
  [
    18.48102,
    73.94467
  ],
  [
    18.48215,
    73.94495
  ],
  [
    18.48281,
    73.94514
  ],
  [
    18.48335,
    73.94533
  ],
  [
    18.48369,
    73.94538
  ],
  [
    18.48404,
    73.94539
  ],
  [
    18.48427,
    73.94538
  ],
  [
    18.48458,
    73.94541
  ],
  [
    18.48504,
    73.94555
  ],
  [
    18.48564,
    73.94571
  ],
  [
    18.48596,
    73.94576
  ],
  [
    18.4866,
    73.94581
  ],
  [
    18.48713,
    73.94584
  ],
  [
    18.48731,
    73.94586
  ],
  [
    18.48776,
    73.94615
  ],
  [
    18.48815,
    73.94643
  ],
  [
    18.48832,
    73.9466
  ],
  [
    18.48807,
    73.94768
  ],
  [
    18.48803,
    73.94801
  ],
  [
    18.48802,
    73.94806
  ],
  [
    18.488,
    73.94816
  ],
  [
    18.488,
    73.94824
  ],
  [
    18.488,
    73.94828
  ],
  [
    18.48804,
    73.94831
  ],
  [
    18.48815,
    73.94834
  ],
  [
    18.48831,
    73.94836
  ],
  [
    18.48847,
    73.94839
  ],
  [
    18.48857,
    73.94842
  ],
  [
    18.48881,
    73.94849
  ],
  [
    18.48905,
    73.94857
  ],
  [
    18.4893,
    73.94865
  ],
  [
    18.48933,
    73.94866
  ],
  [
    18.48951,
    73.94872
  ],
  [
    18.48961,
    73.94875
  ],
  [
    18.48969,
    73.94878
  ],
  [
    18.48999,
    73.94888
  ],
  [
    18.49024,
    73.94896
  ],
  [
    18.49059,
    73.94907
  ],
  [
    18.49087,
    73.94917
  ],
  [
    18.49112,
    73.94927
  ],
  [
    18.49122,
    73.94931
  ],
  [
    18.49074,
    73.94966
  ],
  [
    18.49039,
    73.94993
  ],
  [
    18.4901,
    73.95015
  ],
  [
    18.48987,
    73.95033
  ],
  [
    18.48957,
    73.95055
  ],
  [
    18.48982,
    73.95068
  ],
  [
    18.48991,
    73.95069
  ],
  [
    18.49044,
    73.95086
  ],
  [
    18.49094,
    73.95106
  ],
  [
    18.49246,
    73.95169
  ],
  [
    18.49356,
    73.9521
  ],
  [
    18.49378,
    73.95219
  ],
  [
    18.49378,
    73.95182
  ],
  [
    18.4938,
    73.95136
  ],
  [
    18.49381,
    73.9512
  ],
  [
    18.49382,
    73.95108
  ],
  [
    18.49389,
    73.95079
  ],
  [
    18.4939,
    73.95072
  ],
  [
    18.49682,
    73.95106
  ],
  [
    18.49754,
    73.95115
  ],
  [
    18.49753,
    73.95128
  ],
  [
    18.49734,
    73.95209
  ],
  [
    18.49719,
    73.95258
  ],
  [
    18.49681,
    73.95345
  ],
  [
    18.49666,
    73.95384
  ],
  [
    18.49631,
    73.95475
  ],
  [
    18.49628,
    73.95484
  ],
  [
    18.49618,
    73.95506
  ],
  [
    18.49608,
    73.95532
  ],
  [
    18.49581,
    73.95596
  ],
  [
    18.49559,
    73.95645
  ],
  [
    18.49534,
    73.95708
  ],
  [
    18.4952,
    73.95737
  ],
  [
    18.49506,
    73.95757
  ],
  [
    18.49461,
    73.95807
  ],
  [
    18.49409,
    73.95856
  ],
  [
    18.49367,
    73.95894
  ],
  [
    18.49233,
    73.96022
  ],
  [
    18.49217,
    73.96562
  ],
  [
    18.49701,
    73.9663
  ],
  [
    18.49642,
    73.97068
  ],
  [
    18.49808,
    73.97106
  ],
  [
    18.50291,
    73.97254
  ],
  [
    18.50987,
    73.97182
  ],
  [
    18.51317,
    73.96985
  ],
  [
    18.51479,
    73.96629
  ],
  [
    18.51718,
    73.96101
  ],
  [
    18.51981,
    73.95503
  ],
  [
    18.52014,
    73.95407
  ],
  [
    18.52062,
    73.95274
  ],
  [
    18.52105,
    73.95173
  ],
  [
    18.52107,
    73.95036
  ],
  [
    18.52169,
    73.94907
  ],
  [
    18.52231,
    73.9471
  ],
  [
    18.52387,
    73.94201
  ],
  [
    18.52392,
    73.94183
  ],
  [
    18.5241,
    73.94126
  ],
  [
    18.52425,
    73.94066
  ],
  [
    18.52438,
    73.94015
  ],
  [
    18.52477,
    73.93859
  ],
  [
    18.52495,
    73.93802
  ],
  [
    18.52537,
    73.93672
  ],
  [
    18.52571,
    73.93589
  ],
  [
    18.52615,
    73.93412
  ],
  [
    18.5264,
    73.93228
  ],
  [
    18.52593,
    73.93229
  ],
  [
    18.52542,
    73.93231
  ],
  [
    18.52432,
    73.93229
  ],
  [
    18.52407,
    73.93227
  ],
  [
    18.52364,
    73.93223
  ],
  [
    18.52359,
    73.93223
  ],
  [
    18.5221,
    73.93209
  ],
  [
    18.52151,
    73.93214
  ],
  [
    18.52022,
    73.93256
  ],
  [
    18.51887,
    73.9328
  ],
  [
    18.5169,
    73.93307
  ],
  [
    18.51637,
    73.9331
  ],
  [
    18.51532,
    73.93287
  ],
  [
    18.51427,
    73.93261
  ],
  [
    18.51213,
    73.93257
  ],
  [
    18.51153,
    73.93251
  ],
  [
    18.51117,
    73.93235
  ],
  [
    18.5102,
    73.9314
  ],
  [
    18.50983,
    73.93106
  ],
  [
    18.50935,
    73.93062
  ],
  [
    18.50849,
    73.92989
  ],
  [
    18.50865,
    73.9293
  ],
  [
    18.50907,
    73.92601
  ],
  [
    18.50917,
    73.9249
  ],
  [
    18.5092,
    73.92362
  ],
  [
    18.50908,
    73.92362
  ],
  [
    18.50887,
    73.92355
  ],
  [
    18.50891,
    73.92336
  ],
  [
    18.50902,
    73.92225
  ],
  [
    18.5092,
    73.92181
  ],
  [
    18.5094,
    73.92155
  ],
  [
    18.50957,
    73.92142
  ],
  [
    18.50988,
    73.92125
  ],
  [
    18.5148,
    73.91947
  ],
  [
    18.51671,
    73.91898
  ],
  [
    18.51741,
    73.91878
  ],
  [
    18.51768,
    73.91866
  ],
  [
    18.51792,
    73.91847
  ],
  [
    18.51812,
    73.91825
  ],
  [
    18.51852,
    73.91819
  ],
  [
    18.51993,
    73.91828
  ],
  [
    18.51978,
    73.91651
  ],
  [
    18.5198,
    73.91439
  ],
  [
    18.5198,
    73.91246
  ],
  [
    18.51984,
    73.90994
  ],
  [
    18.51984,
    73.90969
  ],
  [
    18.51972,
    73.90968
  ],
  [
    18.51923,
    73.90964
  ],
  [
    18.51906,
    73.90963
  ],
  [
    18.51897,
    73.90962
  ],
  [
    18.51881,
    73.9096
  ],
  [
    18.51818,
    73.90963
  ],
  [
    18.5173,
    73.90955
  ],
  [
    18.51681,
    73.9095
  ],
  [
    18.51598,
    73.90948
  ],
  [
    18.51553,
    73.9096
  ],
  [
    18.51497,
    73.90961
  ],
  [
    18.51496,
    73.90945
  ],
  [
    18.51503,
    73.90908
  ],
  [
    18.51514,
    73.90876
  ],
  [
    18.51543,
    73.9084
  ],
  [
    18.5156,
    73.90808
  ],
  [
    18.51569,
    73.90769
  ],
  [
    18.51573,
    73.90733
  ],
  [
    18.51573,
    73.90721
  ],
  [
    18.51571,
    73.90721
  ],
  [
    18.51551,
    73.90721
  ],
  [
    18.515,
    73.90718
  ],
  [
    18.5145,
    73.90715
  ],
  [
    18.5142,
    73.90713
  ],
  [
    18.51396,
    73.90732
  ],
  [
    18.51342,
    73.90786
  ],
  [
    18.51301,
    73.90825
  ],
  [
    18.5116,
    73.90951
  ],
  [
    18.51088,
    73.91023
  ],
  [
    18.50979,
    73.91139
  ],
  [
    18.50925,
    73.91191
  ],
  [
    18.50783,
    73.91327
  ],
  [
    18.50574,
    73.91527
  ],
  [
    18.50584,
    73.91493
  ],
  [
    18.50608,
    73.9141
  ],
  [
    18.50614,
    73.91386
  ],
  [
    18.50622,
    73.91352
  ],
  [
    18.50625,
    73.91338
  ],
  [
    18.50631,
    73.91308
  ],
  [
    18.50633,
    73.91297
  ],
  [
    18.50636,
    73.91283
  ],
  [
    18.50635,
    73.91261
  ],
  [
    18.50529,
    73.91259
  ],
  [
    18.50494,
    73.91258
  ],
  [
    18.50461,
    73.91257
  ],
  [
    18.50438,
    73.91256
  ],
  [
    18.5041,
    73.91257
  ],
  [
    18.50304,
    73.9125
  ],
  [
    18.50257,
    73.91248
  ],
  [
    18.50236,
    73.91253
  ],
  [
    18.50198,
    73.91269
  ],
  [
    18.50176,
    73.91292
  ],
  [
    18.50173,
    73.91303
  ],
  [
    18.50174,
    73.91315
  ],
  [
    18.50179,
    73.91336
  ],
  [
    18.50185,
    73.91354
  ],
  [
    18.50204,
    73.91383
  ],
  [
    18.50208,
    73.91407
  ],
  [
    18.5021,
    73.91425
  ],
  [
    18.50216,
    73.91437
  ],
  [
    18.5022,
    73.91444
  ],
  [
    18.50221,
    73.91451
  ],
  [
    18.5022,
    73.91458
  ],
  [
    18.50216,
    73.91465
  ],
  [
    18.50202,
    73.91487
  ],
  [
    18.50189,
    73.91508
  ],
  [
    18.50186,
    73.91517
  ],
  [
    18.50205,
    73.91535
  ],
  [
    18.5022,
    73.91551
  ],
  [
    18.50222,
    73.91566
  ],
  [
    18.50217,
    73.91592
  ],
  [
    18.50204,
    73.91614
  ],
  [
    18.50181,
    73.9164
  ],
  [
    18.50162,
    73.91656
  ],
  [
    18.50126,
    73.91679
  ],
  [
    18.501,
    73.91698
  ],
  [
    18.50059,
    73.91748
  ],
  [
    18.50017,
    73.9172
  ]
];

export default polygonCoordsHadapsar;