// Define coordinates for the polygon (wagholi area)
const polygonCoordsWagholi = [
  [
    18.58955,
    73.98425
  ],
  [
    18.59402,
    73.98408
  ],
  [
    18.59825,
    73.98357
  ],
  [
    18.59011,
    73.97396
  ],
  [
    18.591,
    73.97345
  ],
  [
    18.59141,
    73.97315
  ],
  [
    18.59152,
    73.97149
  ],
  [
    18.59161,
    73.97018
  ],
  [
    18.59417,
    73.97139
  ],
  [
    18.5997,
    73.96821
  ],
  [
    18.59791,
    73.96049
  ],
  [
    18.58961,
    73.96409
  ],
  [
    18.58228,
    73.95215
  ],
  [
    18.57567,
    73.95154
  ],
  [
    18.57439,
    73.951
  ],
  [
    18.57327,
    73.95072
  ],
  [
    18.57252,
    73.95069
  ],
  [
    18.57099,
    73.95078
  ],
  [
    18.56995,
    73.95084
  ],
  [
    18.56915,
    73.95095
  ],
  [
    18.57161,
    73.9542
  ],
  [
    18.57444,
    73.95951
  ],
  [
    18.57686,
    73.96583
  ],
  [
    18.57732,
    73.96836
  ],
  [
    18.57823,
    73.97122
  ],
  [
    18.58077,
    73.97733
  ],
  [
    18.57696,
    73.97709
  ],
  [
    18.57657,
    73.97739
  ],
  [
    18.57089,
    73.97518
  ],
  [
    18.56937,
    73.97511
  ],
  [
    18.56629,
    73.97457
  ],
  [
    18.56857,
    73.97878
  ],
  [
    18.56774,
    73.99033
  ],
  [
    18.5701,
    74.00275
  ],
  [
    18.57488,
    74.00552
  ],
  [
    18.58159,
    74.00917
  ],
  [
    18.58189,
    74.01227
  ],
  [
    18.58352,
    74.01939
  ],
  [
    18.58409,
    74.02613
  ],
  [
    18.59096,
    74.03008
  ],
  [
    18.59625,
    74.03042
  ],
  [
    18.59804,
    74.02849
  ],
  [
    18.59544,
    74.01845
  ],
  [
    18.59372,
    74.01383
  ],
  [
    18.59333,
    74.01219
  ],
  [
    18.59488,
    74.01165
  ],
  [
    18.59495,
    74.00995
  ],
  [
    18.60214,
    74.00877
  ],
  [
    18.60362,
    74.00921
  ],
  [
    18.60402,
    74.01028
  ],
  [
    18.60839,
    74.00726
  ],
  [
    18.60888,
    74.00797
  ],
  [
    18.61253,
    74.00373
  ],
  [
    18.61056,
    73.99983
  ],
  [
    18.6062,
    73.99181
  ],
  [
    18.5972,
    73.99213
  ],
  [
    18.59258,
    73.99283
  ],
  [
    18.59103,
    73.98998
  ],
  [
    18.5912,
    73.98643
  ],
  [
    18.58968,
    73.98635
  ],
  [
    18.58952,
    73.98425
  ]
];

export default polygonCoordsWagholi;