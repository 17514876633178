// Define coordinates for the polygon (Punawale area)
const polygonCoordsPunawale = [
  [
    18.6361,
    73.75391
  ],
  [
    18.63793,
    73.7539
  ],
  [
    18.63844,
    73.7539
  ],
  [
    18.6392,
    73.75388
  ],
  [
    18.63941,
    73.75387
  ],
  [
    18.63997,
    73.75399
  ],
  [
    18.64035,
    73.75396
  ],
  [
    18.64088,
    73.75386
  ],
  [
    18.6412,
    73.7536
  ],
  [
    18.64143,
    73.75322
  ],
  [
    18.64149,
    73.75209
  ],
  [
    18.64136,
    73.75096
  ],
  [
    18.6412,
    73.75006
  ],
  [
    18.64106,
    73.75003
  ],
  [
    18.64067,
    73.74943
  ],
  [
    18.64041,
    73.74912
  ],
  [
    18.63976,
    73.74865
  ],
  [
    18.63964,
    73.74777
  ],
  [
    18.63969,
    73.74759
  ],
  [
    18.63979,
    73.74615
  ],
  [
    18.64005,
    73.74469
  ],
  [
    18.64039,
    73.74376
  ],
  [
    18.6407,
    73.74299
  ],
  [
    18.64077,
    73.74281
  ],
  [
    18.64147,
    73.7418
  ],
  [
    18.64266,
    73.74083
  ],
  [
    18.64278,
    73.74073
  ],
  [
    18.64361,
    73.73987
  ],
  [
    18.64462,
    73.73896
  ],
  [
    18.64481,
    73.73859
  ],
  [
    18.6448,
    73.73792
  ],
  [
    18.6414,
    73.73569
  ],
  [
    18.64186,
    73.73294
  ],
  [
    18.64216,
    73.73228
  ],
  [
    18.6397,
    73.73016
  ],
  [
    18.63697,
    73.72927
  ],
  [
    18.63398,
    73.72786
  ],
  [
    18.62137,
    73.72284
  ],
  [
    18.6197,
    73.72782
  ],
  [
    18.6182,
    73.73155
  ],
  [
    18.62164,
    73.73491
  ],
  [
    18.62298,
    73.73586
  ],
  [
    18.62431,
    73.73694
  ],
  [
    18.62609,
    73.73828
  ],
  [
    18.62789,
    73.73976
  ],
  [
    18.63048,
    73.74193
  ],
  [
    18.63169,
    73.74306
  ],
  [
    18.63316,
    73.74616
  ],
  [
    18.63332,
    73.7469
  ],
  [
    18.63332,
    73.7469
  ],
  [
    18.63427,
    73.74665
  ],
  [
    18.63442,
    73.74782
  ],
  [
    18.63493,
    73.75169
  ],
  [
    18.63496,
    73.75382
  ],
  [
    18.63611,
    73.75392
  ]
];

export default polygonCoordsPunawale;