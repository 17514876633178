// Define coordinates for the polygon (NIBM area)
const polygonCoordsNIBM = [
  [
    18.45926,
    73.90726
  ],
  [
    18.46455,
    73.91129
  ],
  [
    18.4687,
    73.91326
  ],
  [
    18.47293,
    73.91395
  ],
  [
    18.47724,
    73.91206
  ],
  [
    18.48147,
    73.90854
  ],
  [
    18.48318,
    73.90433
  ],
  [
    18.4844,
    73.90004
  ],
  [
    18.48277,
    73.89403
  ],
  [
    18.47886,
    73.88905
  ],
  [
    18.473,
    73.88708
  ],
  [
    18.47086,
    73.8874
  ],
  [
    18.46935,
    73.88761
  ],
  [
    18.46899,
    73.89227
  ],
  [
    18.46759,
    73.88784
  ],
  [
    18.46646,
    73.88796
  ],
  [
    18.4642,
    73.8882
  ],
  [
    18.46168,
    73.88974
  ],
  [
    18.45867,
    73.8942
  ],
  [
    18.4572,
    73.90047
  ],
  [
    18.45924,
    73.90725
  ]
];

export default polygonCoordsNIBM;