// Define coordinates for the polygon (Kharadi area)
const polygonCoordsKharadi = [
  [
    18.53704,
    73.93408
  ],
  [
    18.5371,
    73.93461
  ],
  [
    18.53704,
    73.93508
  ],
  [
    18.53666,
    73.93568
  ],
  [
    18.53643,
    73.93617
  ],
  [
    18.53638,
    73.93664
  ],
  [
    18.53638,
    73.93664
  ],
  [
    18.53632,
    73.93719
  ],
  [
    18.53647,
    73.93846
  ],
  [
    18.53727,
    73.93939
  ],
  [
    18.53873,
    73.9405
  ],
  [
    18.5397,
    73.9411
  ],
  [
    18.54046,
    73.94129
  ],
  [
    18.54172,
    73.9416
  ],
  [
    18.54367,
    73.94286
  ],
  [
    18.54484,
    73.94399
  ],
  [
    18.54548,
    73.94501
  ],
  [
    18.54591,
    73.94569
  ],
  [
    18.5461,
    73.94742
  ],
  [
    18.54614,
    73.94797
  ],
  [
    18.54629,
    73.94999
  ],
  [
    18.5466,
    73.954
  ],
  [
    18.54669,
    73.95461
  ],
  [
    18.54698,
    73.95648
  ],
  [
    18.54726,
    73.95762
  ],
  [
    18.54726,
    73.95924
  ],
  [
    18.54695,
    73.96064
  ],
  [
    18.54752,
    73.96132
  ],
  [
    18.54917,
    73.96138
  ],
  [
    18.54985,
    73.96155
  ],
  [
    18.55451,
    73.9623
  ],
  [
    18.55661,
    73.96252
  ],
  [
    18.55853,
    73.96267
  ],
  [
    18.55926,
    73.96285
  ],
  [
    18.55952,
    73.9606
  ],
  [
    18.56048,
    73.95868
  ],
  [
    18.5623,
    73.95706
  ],
  [
    18.56258,
    73.9561
  ],
  [
    18.56285,
    73.95501
  ],
  [
    18.563,
    73.95444
  ],
  [
    18.56357,
    73.95417
  ],
  [
    18.56404,
    73.95397
  ],
  [
    18.56414,
    73.9539
  ],
  [
    18.56432,
    73.95378
  ],
  [
    18.56482,
    73.95343
  ],
  [
    18.5651,
    73.95322
  ],
  [
    18.56518,
    73.95316
  ],
  [
    18.56527,
    73.95309
  ],
  [
    18.56575,
    73.95272
  ],
  [
    18.56605,
    73.95247
  ],
  [
    18.56656,
    73.95219
  ],
  [
    18.56698,
    73.95197
  ],
  [
    18.56791,
    73.95155
  ],
  [
    18.56834,
    73.95134
  ],
  [
    18.5691,
    73.95097
  ],
  [
    18.56894,
    73.9507
  ],
  [
    18.56861,
    73.95013
  ],
  [
    18.56827,
    73.94958
  ],
  [
    18.56807,
    73.94925
  ],
  [
    18.56787,
    73.94891
  ],
  [
    18.56779,
    73.94878
  ],
  [
    18.56762,
    73.9485
  ],
  [
    18.56736,
    73.94801
  ],
  [
    18.56721,
    73.94768
  ],
  [
    18.56703,
    73.94723
  ],
  [
    18.56692,
    73.94695
  ],
  [
    18.56679,
    73.94658
  ],
  [
    18.56673,
    73.94637
  ],
  [
    18.5667,
    73.94628
  ],
  [
    18.5666,
    73.94596
  ],
  [
    18.56647,
    73.94557
  ],
  [
    18.56636,
    73.94529
  ],
  [
    18.56624,
    73.94505
  ],
  [
    18.566,
    73.94477
  ],
  [
    18.56588,
    73.94466
  ],
  [
    18.56553,
    73.94435
  ],
  [
    18.56504,
    73.94395
  ],
  [
    18.56488,
    73.94379
  ],
  [
    18.56484,
    73.94375
  ],
  [
    18.56469,
    73.9436
  ],
  [
    18.56432,
    73.94324
  ],
  [
    18.56388,
    73.94276
  ],
  [
    18.56379,
    73.94265
  ],
  [
    18.56364,
    73.94245
  ],
  [
    18.56342,
    73.94209
  ],
  [
    18.56311,
    73.94141
  ],
  [
    18.56285,
    73.94081
  ],
  [
    18.5628,
    73.94069
  ],
  [
    18.5627,
    73.9405
  ],
  [
    18.56257,
    73.94014
  ],
  [
    18.56244,
    73.93975
  ],
  [
    18.5624,
    73.93958
  ],
  [
    18.56231,
    73.93928
  ],
  [
    18.56219,
    73.93866
  ],
  [
    18.55937,
    73.9383
  ],
  [
    18.55954,
    73.93775
  ],
  [
    18.55967,
    73.93704
  ],
  [
    18.55974,
    73.93694
  ],
  [
    18.55988,
    73.93679
  ],
  [
    18.56005,
    73.93667
  ],
  [
    18.56042,
    73.93639
  ],
  [
    18.56043,
    73.93555
  ],
  [
    18.56033,
    73.93441
  ],
  [
    18.56037,
    73.93402
  ],
  [
    18.56052,
    73.93337
  ],
  [
    18.56065,
    73.93268
  ],
  [
    18.56097,
    73.93116
  ],
  [
    18.56045,
    73.93104
  ],
  [
    18.55991,
    73.93104
  ],
  [
    18.559,
    73.93131
  ],
  [
    18.55796,
    73.93153
  ],
  [
    18.55794,
    73.92812
  ],
  [
    18.55732,
    73.92814
  ],
  [
    18.55696,
    73.92817
  ],
  [
    18.55657,
    73.9282
  ],
  [
    18.55628,
    73.92825
  ],
  [
    18.55619,
    73.92827
  ],
  [
    18.55576,
    73.92835
  ],
  [
    18.55546,
    73.92839
  ],
  [
    18.5548,
    73.92846
  ],
  [
    18.55455,
    73.92849
  ],
  [
    18.55417,
    73.92851
  ],
  [
    18.5538,
    73.92854
  ],
  [
    18.55379,
    73.92854
  ],
  [
    18.55378,
    73.92854
  ],
  [
    18.55343,
    73.92858
  ],
  [
    18.55306,
    73.92862
  ],
  [
    18.5527,
    73.92869
  ],
  [
    18.5527,
    73.92869
  ],
  [
    18.55241,
    73.92873
  ],
  [
    18.55179,
    73.92882
  ],
  [
    18.55163,
    73.92884
  ],
  [
    18.55116,
    73.92891
  ],
  [
    18.55104,
    73.92893
  ],
  [
    18.55042,
    73.92902
  ],
  [
    18.55011,
    73.92907
  ],
  [
    18.54981,
    73.92918
  ],
  [
    18.54967,
    73.92922
  ],
  [
    18.5495,
    73.92928
  ],
  [
    18.54941,
    73.92931
  ],
  [
    18.54918,
    73.92939
  ],
  [
    18.54824,
    73.92974
  ],
  [
    18.54803,
    73.92982
  ],
  [
    18.54765,
    73.92991
  ],
  [
    18.5471,
    73.93005
  ],
  [
    18.54692,
    73.93009
  ],
  [
    18.54608,
    73.93037
  ],
  [
    18.5458,
    73.93045
  ],
  [
    18.5455,
    73.93054
  ],
  [
    18.54511,
    73.93068
  ],
  [
    18.54505,
    73.9307
  ],
  [
    18.54381,
    73.93102
  ],
  [
    18.54375,
    73.93104
  ],
  [
    18.54307,
    73.93123
  ],
  [
    18.54214,
    73.93141
  ],
  [
    18.54228,
    73.93481
  ],
  [
    18.54229,
    73.93503
  ],
  [
    18.54218,
    73.93501
  ],
  [
    18.54215,
    73.935
  ],
  [
    18.54183,
    73.93494
  ],
  [
    18.54178,
    73.93493
  ],
  [
    18.54118,
    73.93483
  ],
  [
    18.54073,
    73.93472
  ],
  [
    18.54047,
    73.93468
  ],
  [
    18.54036,
    73.93466
  ],
  [
    18.54023,
    73.93463
  ],
  [
    18.53993,
    73.93458
  ],
  [
    18.53982,
    73.93453
  ],
  [
    18.53963,
    73.93449
  ],
  [
    18.5395,
    73.93446
  ],
  [
    18.53931,
    73.93442
  ],
  [
    18.5384,
    73.93423
  ],
  [
    18.53817,
    73.93418
  ],
  [
    18.53792,
    73.93413
  ],
  [
    18.53777,
    73.9341
  ],
  [
    18.53749,
    73.93405
  ],
  [
    18.53703,
    73.93395
  ],
  [
    18.53702,
    73.93403
  ]
];

export default polygonCoordsKharadi;