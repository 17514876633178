// Define coordinates for the polygon (Ravet area)
const polygonCoordsRavet = [
  [
    18.65085,
    73.72206
  ],
  [
    18.64883,
    73.72289
  ],
  [
    18.6476,
    73.72339
  ],
  [
    18.64724,
    73.72385
  ],
  [
    18.64676,
    73.7248
  ],
  [
    18.64672,
    73.7255
  ],
  [
    18.64665,
    73.72579
  ],
  [
    18.64651,
    73.72618
  ],
  [
    18.64631,
    73.72712
  ],
  [
    18.646,
    73.72812
  ],
  [
    18.64586,
    73.72864
  ],
  [
    18.64578,
    73.72894
  ],
  [
    18.64509,
    73.73026
  ],
  [
    18.64395,
    73.7315
  ],
  [
    18.64269,
    73.73263
  ],
  [
    18.6424,
    73.73299
  ],
  [
    18.64183,
    73.73367
  ],
  [
    18.64155,
    73.73472
  ],
  [
    18.6415,
    73.73527
  ],
  [
    18.64195,
    73.736
  ],
  [
    18.6428,
    73.73652
  ],
  [
    18.64387,
    73.73697
  ],
  [
    18.64453,
    73.73767
  ],
  [
    18.64471,
    73.73777
  ],
  [
    18.64477,
    73.73793
  ],
  [
    18.64478,
    73.7386
  ],
  [
    18.64459,
    73.73897
  ],
  [
    18.64358,
    73.73988
  ],
  [
    18.64275,
    73.74074
  ],
  [
    18.64263,
    73.74084
  ],
  [
    18.64144,
    73.74181
  ],
  [
    18.64074,
    73.74282
  ],
  [
    18.64067,
    73.743
  ],
  [
    18.64036,
    73.74377
  ],
  [
    18.64002,
    73.7447
  ],
  [
    18.63976,
    73.74616
  ],
  [
    18.63966,
    73.7476
  ],
  [
    18.63961,
    73.74778
  ],
  [
    18.63973,
    73.74866
  ],
  [
    18.64038,
    73.74913
  ],
  [
    18.64064,
    73.74944
  ],
  [
    18.64103,
    73.75004
  ],
  [
    18.64117,
    73.75007
  ],
  [
    18.64124,
    73.7501
  ],
  [
    18.64216,
    73.75014
  ],
  [
    18.64308,
    73.75023
  ],
  [
    18.64303,
    73.75064
  ],
  [
    18.643,
    73.75106
  ],
  [
    18.64302,
    73.75161
  ],
  [
    18.64307,
    73.75247
  ],
  [
    18.64309,
    73.75275
  ],
  [
    18.6431,
    73.75289
  ],
  [
    18.64319,
    73.75313
  ],
  [
    18.64342,
    73.75373
  ],
  [
    18.6435,
    73.7541
  ],
  [
    18.6436,
    73.75462
  ],
  [
    18.64375,
    73.75526
  ],
  [
    18.64378,
    73.7554
  ],
  [
    18.64385,
    73.75571
  ],
  [
    18.64393,
    73.75599
  ],
  [
    18.64404,
    73.75629
  ],
  [
    18.64414,
    73.75652
  ],
  [
    18.64421,
    73.75663
  ],
  [
    18.64426,
    73.75669
  ],
  [
    18.64435,
    73.75679
  ],
  [
    18.64459,
    73.75705
  ],
  [
    18.64506,
    73.75748
  ],
  [
    18.64554,
    73.75796
  ],
  [
    18.64608,
    73.75848
  ],
  [
    18.64633,
    73.75872
  ],
  [
    18.64636,
    73.75876
  ],
  [
    18.64679,
    73.75916
  ],
  [
    18.64717,
    73.75951
  ],
  [
    18.64762,
    73.75993
  ],
  [
    18.64772,
    73.76003
  ],
  [
    18.64791,
    73.76021
  ],
  [
    18.64825,
    73.76052
  ],
  [
    18.64843,
    73.76068
  ],
  [
    18.64866,
    73.7609
  ],
  [
    18.64877,
    73.761
  ],
  [
    18.6489,
    73.76112
  ],
  [
    18.64913,
    73.76134
  ],
  [
    18.64921,
    73.76142
  ],
  [
    18.64929,
    73.7615
  ],
  [
    18.6494,
    73.76161
  ],
  [
    18.64952,
    73.76173
  ],
  [
    18.64959,
    73.7618
  ],
  [
    18.64968,
    73.76189
  ],
  [
    18.65,
    73.76219
  ],
  [
    18.65142,
    73.76059
  ],
  [
    18.65163,
    73.76039
  ],
  [
    18.6522,
    73.75984
  ],
  [
    18.65248,
    73.75961
  ],
  [
    18.65324,
    73.75906
  ],
  [
    18.65342,
    73.75893
  ],
  [
    18.65421,
    73.75843
  ],
  [
    18.65544,
    73.75776
  ],
  [
    18.65584,
    73.75755
  ],
  [
    18.6564,
    73.75726
  ],
  [
    18.65759,
    73.75667
  ],
  [
    18.65865,
    73.75608
  ],
  [
    18.65935,
    73.75567
  ],
  [
    18.65941,
    73.75564
  ],
  [
    18.65989,
    73.75539
  ],
  [
    18.66084,
    73.75451
  ],
  [
    18.66167,
    73.75358
  ],
  [
    18.66185,
    73.75327
  ],
  [
    18.66185,
    73.75327
  ],
  [
    18.66229,
    73.75251
  ],
  [
    18.663,
    73.75105
  ],
  [
    18.66359,
    73.74906
  ],
  [
    18.66403,
    73.74711
  ],
  [
    18.66448,
    73.74607
  ],
  [
    18.66486,
    73.74539
  ],
  [
    18.6654,
    73.74461
  ],
  [
    18.66597,
    73.74396
  ],
  [
    18.66649,
    73.74349
  ],
  [
    18.66708,
    73.74302
  ],
  [
    18.66817,
    73.7424
  ],
  [
    18.66876,
    73.74214
  ],
  [
    18.66986,
    73.74187
  ],
  [
    18.6699,
    73.74174
  ],
  [
    18.66991,
    73.74165
  ],
  [
    18.66988,
    73.74151
  ],
  [
    18.66973,
    73.74136
  ],
  [
    18.66964,
    73.74119
  ],
  [
    18.66958,
    73.74118
  ],
  [
    18.66954,
    73.74109
  ],
  [
    18.6695,
    73.74098
  ],
  [
    18.66949,
    73.74086
  ],
  [
    18.66946,
    73.74069
  ],
  [
    18.66946,
    73.74054
  ],
  [
    18.66508,
    73.73964
  ],
  [
    18.66496,
    73.73988
  ],
  [
    18.66368,
    73.73812
  ],
  [
    18.66,
    73.73051
  ],
  [
    18.65868,
    73.73102
  ],
  [
    18.65841,
    73.73022
  ],
  [
    18.65812,
    73.72912
  ],
  [
    18.65722,
    73.728
  ],
  [
    18.65552,
    73.72636
  ],
  [
    18.65505,
    73.72378
  ],
  [
    18.65236,
    73.72123
  ],
  [
    18.65301,
    73.72061
  ],
  [
    18.65375,
    73.71973
  ],
  [
    18.65439,
    73.71801
  ],
  [
    18.65822,
    73.71934
  ],
  [
    18.65266,
    73.70334
  ],
  [
    18.65323,
    73.70158
  ],
  [
    18.65164,
    73.70145
  ],
  [
    18.65123,
    73.7045
  ],
  [
    18.65115,
    73.70622
  ],
  [
    18.6518,
    73.70819
  ],
  [
    18.6556,
    73.7162
  ],
  [
    18.65184,
    73.72052
  ],
  [
    18.65185,
    73.72162
  ],
  [
    18.65087,
    73.72204
  ]
];

export default polygonCoordsRavet;