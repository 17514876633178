
// Define coordinates for the polygon (Tathawade area)
const polygonCoordsTathawade = [
  [
    18.63331,
    73.7469
  ],
  [
    18.63331,
    73.7469
  ],
  [
    18.63315,
    73.74616
  ],
  [
    18.63168,
    73.74306
  ],
  [
    18.63076,
    73.7422
  ],
  [
    18.62606,
    73.73828
  ],
  [
    18.62429,
    73.73695
  ],
  [
    18.62268,
    73.73568
  ],
  [
    18.6192,
    73.73321
  ],
  [
    18.61187,
    73.72833
  ],
  [
    18.60864,
    73.73374
  ],
  [
    18.60655,
    73.73629
  ],
  [
    18.60485,
    73.73828
  ],
  [
    18.60532,
    73.74301
  ],
  [
    18.60614,
    73.74794
  ],
  [
    18.60656,
    73.7506
  ],
  [
    18.60672,
    73.75174
  ],
  [
    18.6065,
    73.75182
  ],
  [
    18.60544,
    73.75218
  ],
  [
    18.60558,
    73.75233
  ],
  [
    18.60587,
    73.75267
  ],
  [
    18.60615,
    73.75313
  ],
  [
    18.60645,
    73.7539
  ],
  [
    18.60669,
    73.75458
  ],
  [
    18.60699,
    73.7554
  ],
  [
    18.60707,
    73.75561
  ],
  [
    18.6074,
    73.75598
  ],
  [
    18.60787,
    73.7565
  ],
  [
    18.60821,
    73.75683
  ],
  [
    18.60918,
    73.75787
  ],
  [
    18.61013,
    73.75916
  ],
  [
    18.61042,
    73.75956
  ],
  [
    18.61088,
    73.76019
  ],
  [
    18.61129,
    73.76077
  ],
  [
    18.61148,
    73.76101
  ],
  [
    18.61165,
    73.76123
  ],
  [
    18.61179,
    73.76141
  ],
  [
    18.61205,
    73.76176
  ],
  [
    18.61289,
    73.76076
  ],
  [
    18.61691,
    73.75602
  ],
  [
    18.61999,
    73.75779
  ],
  [
    18.62243,
    73.75979
  ],
  [
    18.62626,
    73.76243
  ],
  [
    18.62704,
    73.76077
  ],
  [
    18.62738,
    73.76024
  ],
  [
    18.6281,
    73.75873
  ],
  [
    18.62866,
    73.7576
  ],
  [
    18.62894,
    73.75721
  ],
  [
    18.62928,
    73.75674
  ],
  [
    18.62946,
    73.75649
  ],
  [
    18.62987,
    73.75582
  ],
  [
    18.63032,
    73.75481
  ],
  [
    18.6308,
    73.75415
  ],
  [
    18.6311,
    73.75391
  ],
  [
    18.63203,
    73.75351
  ],
  [
    18.63327,
    73.7536
  ],
  [
    18.63475,
    73.75378
  ],
  [
    18.63494,
    73.7538
  ],
  [
    18.63491,
    73.75167
  ],
  [
    18.6344,
    73.7478
  ],
  [
    18.63425,
    73.74663
  ],
  [
    18.6333,
    73.74688
  ]
];

export default polygonCoordsTathawade;