// Define coordinates for the polygon (Sus area)
const polygonCoordsSus = [
  [
    18.53869,
    73.74532
  ],
  [
    18.53182,
    73.74546
  ],
  [
    18.53064,
    73.74611
  ],
  [
    18.53097,
    73.74619
  ],
  [
    18.5318,
    73.74629
  ],
  [
    18.53471,
    73.74809
  ],
  [
    18.53491,
    73.74833
  ],
  [
    18.53564,
    73.75066
  ],
  [
    18.53652,
    73.75313
  ],
  [
    18.53744,
    73.75471
  ],
  [
    18.53825,
    73.75595
  ],
  [
    18.53994,
    73.75569
  ],
  [
    18.54142,
    73.75748
  ],
  [
    18.54197,
    73.75903
  ],
  [
    18.54164,
    73.76243
  ],
  [
    18.5414,
    73.76277
  ],
  [
    18.54104,
    73.76314
  ],
  [
    18.54069,
    73.76345
  ],
  [
    18.54094,
    73.7642
  ],
  [
    18.54121,
    73.76489
  ],
  [
    18.54136,
    73.76537
  ],
  [
    18.54145,
    73.76554
  ],
  [
    18.54159,
    73.76575
  ],
  [
    18.54178,
    73.7661
  ],
  [
    18.54242,
    73.76696
  ],
  [
    18.54287,
    73.76749
  ],
  [
    18.54327,
    73.76804
  ],
  [
    18.5439,
    73.76889
  ],
  [
    18.54401,
    73.76905
  ],
  [
    18.54422,
    73.76937
  ],
  [
    18.54426,
    73.7694
  ],
  [
    18.54428,
    73.76941
  ],
  [
    18.54433,
    73.76951
  ],
  [
    18.54446,
    73.76936
  ],
  [
    18.549,
    73.7658
  ],
  [
    18.55365,
    73.76325
  ],
  [
    18.55371,
    73.76305
  ],
  [
    18.55395,
    73.76293
  ],
  [
    18.55427,
    73.76295
  ],
  [
    18.55447,
    73.76307
  ],
  [
    18.55459,
    73.76378
  ],
  [
    18.55506,
    73.76392
  ],
  [
    18.55548,
    73.76399
  ],
  [
    18.55561,
    73.76406
  ],
  [
    18.55575,
    73.76425
  ],
  [
    18.55603,
    73.76436
  ],
  [
    18.55623,
    73.76441
  ],
  [
    18.5571,
    73.76465
  ],
  [
    18.55769,
    73.76454
  ],
  [
    18.55822,
    73.76449
  ],
  [
    18.55854,
    73.76449
  ],
  [
    18.55865,
    73.76449
  ],
  [
    18.55949,
    73.76455
  ],
  [
    18.55996,
    73.76462
  ],
  [
    18.56015,
    73.76462
  ],
  [
    18.5605,
    73.76464
  ],
  [
    18.56103,
    73.76467
  ],
  [
    18.56119,
    73.7637
  ],
  [
    18.5615,
    73.76261
  ],
  [
    18.56172,
    73.7615
  ],
  [
    18.56177,
    73.76129
  ],
  [
    18.56192,
    73.76079
  ],
  [
    18.56199,
    73.76057
  ],
  [
    18.56205,
    73.76023
  ],
  [
    18.56209,
    73.75979
  ],
  [
    18.56212,
    73.75881
  ],
  [
    18.56212,
    73.7584
  ],
  [
    18.56214,
    73.75803
  ],
  [
    18.56235,
    73.75754
  ],
  [
    18.5626,
    73.75692
  ],
  [
    18.56274,
    73.75664
  ],
  [
    18.56303,
    73.75624
  ],
  [
    18.56313,
    73.75582
  ],
  [
    18.56317,
    73.7555
  ],
  [
    18.56314,
    73.75511
  ],
  [
    18.56315,
    73.75464
  ],
  [
    18.56302,
    73.75336
  ],
  [
    18.5636,
    73.73993
  ],
  [
    18.55991,
    73.73706
  ],
  [
    18.55612,
    73.73766
  ],
  [
    18.54889,
    73.73793
  ],
  [
    18.53867,
    73.7453
  ]
];

export default polygonCoordsSus;