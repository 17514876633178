import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SearchInput from './SearchInput';
import ContentBox from '../Pages/Home/HomeContentBox';
import { SearchVisibilityContext } from './SearchVisibilityContext';
import $ from 'jquery';
import { appAbsPath, createGa4Event } from '../../Admin/Utils';

//images import
import Box from '../../../Assets/img/box.png';
import Blueright from '../../../Assets/img/blue-right.png';
import Flowers from '../../../Assets/img/flowers.png';
import Stars from '../../../Assets/img/stars.png';
import Hot from '../../../Assets/img/hotsell.svg';
import Fire from '../../../Assets/img/fire.png';
import Affordable from '../../../Assets/img/affordble.png';
import Sky1 from '../../../Assets/img/sky1.png';
import Sky2 from '../../../Assets/img/sky2.png';
import searchbar from '../../../Assets/img/searchbar.svg';

const SearchPopupIrfs = ({ isOpen, togglePopup, iconShow, buttonShow }) => {
    const [internalIsOpen, setInternalIsOpen] = useState(false); // Internal state for the search icon
    const [searchResults, setSearchResults] = useState([]);
    const [localityNamesArray, setLocalityNamesArray] = useState([]); // State for locality names
    const { setSearchOptions } = useContext(SearchVisibilityContext);
    const navigate = useNavigate();

    // Toggle for internal state (original functionality)
    const handleToggle = () => {
        setInternalIsOpen(!internalIsOpen);
    };

    // Close function for both internal and external
    const handleClose = () => {
        // const pathname = window.location.pathname;
        if (togglePopup) {
            togglePopup();
        } else {
            setInternalIsOpen(false);
            // if (pathname === '/irfs') {
            //     navigate('/irfs/projects');
            // }
        }
    };

    const closeSelected = (selectedData) => {
        setSearchOptions(selectedData);
        setSearchResults(prevResults => prevResults.filter(result => result.text !== selectedData));
        setLocalityNamesArray(prevLocalities => prevLocalities.filter(locality => locality !== selectedData));
    };
    const clearAllParams = () => {
        // Clear all parameters by navigating to the pathname only
        navigate(window.location.pathname, { replace: true });
    };


    const getLocalityNamesFromURL = () => {
        const params = new URLSearchParams(window.location.search);
        const localityNames = params.get('locality_name');
        return localityNames ? decodeURIComponent(localityNames).split(',') : [];
    };

    useEffect(() => {
        setLocalityNamesArray(getLocalityNamesFromURL());
    }, [window.location.search]);

    $(document).ready(function () {
        // Select the textarea within the select2 search container
        $('.select2-search.select2-search--inline textarea.select2-search__field').attr('placeholder', 'Search for locality, project, or developer');

        // Optionally, you can add other manipulations
        $('.select2-search.select2-search--inline textarea.select2-search__field').css('width', '100%');
    });

    const combinedIsOpen = isOpen !== undefined ? isOpen : internalIsOpen;

    return (
        <div>
            {
                iconShow && (
                    <div className="searchIcon" onClick={handleToggle}>
                        <svg height="30" viewBox="0 0 32 32" width="30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M32.0012 0H0.0012207V32H32.0012V0Z"></path>
                            <path d="M15.0012 21.9999C18.8671 21.9999 22.0011 18.8659 22.0011 14.9999C22.0011 11.134 18.8671 8 15.0012 8C11.1352 8 8.00122 11.134 8.00122 14.9999C8.00122 18.8659 11.1352 21.9999 15.0012 21.9999Z" stroke="#111112" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                            <path d="M19.9524 19.95L24.0024 24" stroke="#111112" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                        </svg>
                    </div>
                )
            }
            {
                buttonShow && (
                    <div className="searchIcon searchBar text-center" onClick={handleToggle}>
                        <div href='javascript:;' className=''>
                            <img src={searchbar} className='img-fluid' alt="search" />
                        </div>
                    </div>
                )
            }

            <div className={`searchPopup ${combinedIsOpen ? 'open' : ''}`}>
                <div className="headBtns">
                    <a className="closeButton" onClick={handleClose}>
                        <svg width="19" height="16" viewBox="0 0 19 16" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.556 7.847H1M7.45 1L1 7.877l6.45 6.817" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill="none"></path>
                        </svg>
                    </a>
                    <h4 className="mb-0">Search:</h4>
                    {/* <button class="btn btn-primary sc py-1 h-auto">Clear All</button> */}
                </div>
                <div className="searchContent">
                    <SearchInput onSearchResults={setSearchResults} handleClose={handleClose} />
                </div>
                <div className="searchResultsWrapper">
                    <div className="searchResults">
                        {searchResults.length > 0 ? (
                            searchResults.map((result, index) => (
                                <div key={index} className="searchResultItem position-relative">
                                    {result.text}
                                    <span className='crossIcon' onClick={() => closeSelected(result.text)}>×</span>
                                </div>
                            ))
                        ) : localityNamesArray.length > 0 ? (
                            localityNamesArray.map((locality, index) => (
                                <div key={index} className="searchResultItem position-relative">
                                    {locality}
                                    <span
                                        className='crossIcon'
                                        onClick={() => {
                                            closeSelected(locality);
                                            clearAllParams();
                                        }}
                                    >
                                        ×
                                    </span>
                                </div>
                            ))
                        ) : (
                            <div className="noResultsMessage">
                                Your searches will appear here.
                            </div>
                        )}
                    </div>
                </div>
                <div className="recommendedSections">
                    <ContentBox
                        appAbsPath={appAbsPath}
                        createGa4Event={createGa4Event}
                        Box={Box}
                        Blueright={Blueright}
                        Flowers={Flowers}
                        Stars={Stars}
                        Hot={Hot}
                        Fire={Fire}
                        Affordable={Affordable}
                        Sky1={Sky1}
                        Sky2={Sky2}
                        showPara={false}
                        showTopContent={false}
                        showForwardIcon={false}
                        allowLazyLoad={false}
                        handleClose={handleClose}
                        origin={"search"}
                    />
                </div>
                <div className="nextBtn theme-btn" onClick={handleClose}>
                    NEXT
                </div>
            </div>
        </div>
    );
};

export default SearchPopupIrfs;
