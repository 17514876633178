// Define coordinates for the polygon (Moshi area)
const polygonCoordsMoshi = [
  [
    18.68686,
    73.85851
  ],
  [
    18.68792,
    73.85769
  ],
  [
    18.68999,
    73.85842
  ],
  [
    18.6929,
    73.85883
  ],
  [
    18.69577,
    73.85915
  ],
  [
    18.69622,
    73.85653
  ],
  [
    18.69663,
    73.85378
  ],
  [
    18.69761,
    73.84777
  ],
  [
    18.69383,
    73.84725
  ],
  [
    18.69208,
    73.84631
  ],
  [
    18.68976,
    73.8464
  ],
  [
    18.68952,
    73.84571
  ],
  [
    18.68952,
    73.84434
  ],
  [
    18.68842,
    73.84288
  ],
  [
    18.68834,
    73.8376
  ],
  [
    18.68968,
    73.83357
  ],
  [
    18.67793,
    73.82816
  ],
  [
    18.67671,
    73.83284
  ],
  [
    18.67569,
    73.83258
  ],
  [
    18.67378,
    73.83181
  ],
  [
    18.67163,
    73.83091
  ],
  [
    18.66882,
    73.82992
  ],
  [
    18.6641,
    73.82576
  ],
  [
    18.66348,
    73.83008
  ],
  [
    18.66282,
    73.82954
  ],
  [
    18.66207,
    73.83036
  ],
  [
    18.66096,
    73.83042
  ],
  [
    18.65977,
    73.8299
  ],
  [
    18.65887,
    73.83
  ],
  [
    18.65815,
    73.83061
  ],
  [
    18.658,
    73.83108
  ],
  [
    18.65677,
    73.83312
  ],
  [
    18.65637,
    73.83424
  ],
  [
    18.65766,
    73.83447
  ],
  [
    18.65753,
    73.83474
  ],
  [
    18.657,
    73.8364
  ],
  [
    18.65592,
    73.83846
  ],
  [
    18.65545,
    73.84004
  ],
  [
    18.65281,
    73.84013
  ],
  [
    18.65286,
    73.84322
  ],
  [
    18.6505,
    73.8434
  ],
  [
    18.65064,
    73.84525
  ],
  [
    18.64992,
    73.84542
  ],
  [
    18.64986,
    73.84778
  ],
  [
    18.65054,
    73.8478
  ],
  [
    18.65045,
    73.85017
  ],
  [
    18.64882,
    73.85072
  ],
  [
    18.64775,
    73.85077
  ],
  [
    18.64816,
    73.85538
  ],
  [
    18.64938,
    73.85929
  ],
  [
    18.65088,
    73.86105
  ],
  [
    18.651,
    73.86354
  ],
  [
    18.65035,
    73.86766
  ],
  [
    18.65108,
    73.87204
  ],
  [
    18.65759,
    73.86972
  ],
  [
    18.66576,
    73.87096
  ],
  [
    18.67214,
    73.87075
  ],
  [
    18.67649,
    73.86921
  ],
  [
    18.67743,
    73.8699
  ],
  [
    18.67599,
    73.87524
  ],
  [
    18.67727,
    73.87591
  ],
  [
    18.68195,
    73.87703
  ],
  [
    18.68349,
    73.87499
  ],
  [
    18.68428,
    73.87244
  ],
  [
    18.68389,
    73.87032
  ],
  [
    18.68379,
    73.86944
  ],
  [
    18.68368,
    73.86713
  ],
  [
    18.6821,
    73.8669
  ],
  [
    18.68234,
    73.86334
  ],
  [
    18.68283,
    73.85896
  ],
  [
    18.68669,
    73.85943
  ],
  [
    18.68685,
    73.85853
  ]
];

export default polygonCoordsMoshi;